import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[contentEditableModel]',
  host: {
    '(blur)': 'onBlur()',
    '[contentEditable]': 'isEditable',
  },
})
export class EditableDirective {
  @Output() editableModelChange = new EventEmitter();

  @Input() editableModel: any;
  @Input() isEditable: boolean;

  constructor(private elRef: ElementRef) {}

  @HostListener('input')
  onInput() {
    const value = this.elRef.nativeElement.innerText;
    this.editableModelChange.emit(value);
  }

  onBlur() {
    const value = this.elRef.nativeElement.innerText;
    this.editableModelChange.emit(value);
  }
}
