<div id="wrapper">
    <!-- topbar -->
    <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()" (mobileMenuButtonClicked)="onToggleMobileMenu()"
        layoutType="detached"></app-topbar>
    <!-- left side bar-->
    <app-left-sidebar [includeUserProfile]="showSidebarUserInfo">
    </app-left-sidebar>

    <div class="content-page" *ngIf="reRender">
        <div class="content">
            <!-- content -->
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div> <!-- container -->
        </div> <!-- content -->
        <!-- footer -->
        <app-footer></app-footer>
    </div>
</div>

<!-- right sidebar -->
<app-right-sidebar layoutType="detached" [layoutColor]="layoutColor" [layoutWidth]="layoutWidth"
    [menuPosition]="menuPosition" [leftSidebarTheme]="leftSidebarTheme" [leftSidebarType]="leftSidebarType"
    [topbarTheme]="topbarTheme" [showSidebarUserInfo]="showSidebarUserInfo">
</app-right-sidebar>