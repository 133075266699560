import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FirebaseRoutingModule } from './firebase-routing.module';
import { FirebaseComponent } from './firebase/firebase.component';
import { FSidebarComponent } from './components/f-sidebar/f-sidebar.component';
import { FTopbarComponent } from './components/f-topbar/f-topbar.component';
import { FCollectionsComponent } from './components/f-collections/f-collections.component';
import { NewCollectionComponent } from './components/modals/new-collection/new-collection.component';
import { NewDocumentComponent } from './components/modals/new-document/new-document.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FNewFieldComponent } from './components/modals/f-new-field/f-new-field.component';
import { environment2 } from 'src/environments/environment2';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FAuthenticationComponent } from './components/f-authentication/f-authentication.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FCreateAuthUserComponent } from './components/modals/f-create-auth-user/f-create-auth-user.component';
import { FLoaderComponent } from './components/f-loader/f-loader.component';
import { AdvancedTableModule } from 'src/app/shared/advanced-table/advanced-table.module';


@NgModule({
  declarations: [
    FirebaseComponent,
    FSidebarComponent,
    FTopbarComponent,
    FCollectionsComponent,
    NewCollectionComponent,
    NewDocumentComponent,
    FNewFieldComponent,
    FAuthenticationComponent,
    FCreateAuthUserComponent,
    FLoaderComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment2.firebaseConfig),
    AngularFirestoreModule,
    CommonModule,
    FirebaseRoutingModule,
    MatDialogModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFirestoreModule,
    NgbModule,
    AdvancedTableModule
  ],
  exports:[
    FLoaderComponent
  ]
})
export class FirebaseModule { }
