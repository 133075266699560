import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetalertsService {
  constructor() {}

  triggerModal(
    errors: any = null,
    success: boolean = false,
    title: string = '',
    text: string = ''
  ): void {
    let options: SweetAlertOptions = {
      title: null,
      text: null,
      icon: null,
    };

    if (success) {
      options.title =
        title?.length > 0 ? title : 'Email Successfully Triggered';
      options.text =
        text?.length > 0
          ? text
          : 'The email was successfully triggered from the server. You should recieve the daily uploads by email shortly.';
      options.icon = 'success';
    } else {
      if (errors?.length > 0) {
        for (let i = 0; i < errors.length; i++) {
          text = text + '' + errors[i] + '\n\n';
        }
        options.text = text;
      } else {
        options.text =
          text?.length > 0
            ? text
            : 'Please contact the system administrator or try again.';
      }

      options.title = title?.length > 0 ? title : 'Error Triggering Email';
      options.icon = 'error';
    }
    Swal.fire(options);
  }
}
