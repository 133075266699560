import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseService } from '../../../services/firebase.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';

@Component({
  selector: 'app-f-new-field',
  templateUrl: './f-new-field.component.html',
  styleUrls: ['./f-new-field.component.scss']
})
export class FNewFieldComponent implements OnInit {
  @Output() newFieldEvent:any = new EventEmitter();
  @Output() isFieldAdded:any = new EventEmitter();
  @Input() collectionName: any;
  @Input() documentId: any;

  fieldTypes:any[]=[
    {
      type:'string'
    },
    {
      type:'number'
    },
    {
      type:'boolean'
    },
    {
      type: 'null'
    },
    {
      type:'array'
    },
    {
      type:'timestamp'
    },
    {
      type:'geopoint'
    },
    {
      type: 'reference'
    }
  ];
  chsildfieldTypes:any[]=[];
  parrentSelType:any='string';
  parrentSelName:any='';
  parrentSelValue:any;
  childTypeArray:any[]=[
    {
      name:'',
      type:'string',
      value:'',
      timestampDate:'',
      timestampTime:'',
      geoLatitube:'',
      geoLongtitude:'',
      referenceValue:''
    }
  ];
  timestampDate:any;
  timestampTime:any;
  geoLatitube:any;
  geoLongtitude:any;
  referenceValue:any;
  btnLoader:boolean=false;
  btnDisabled:boolean=false;

  constructor(private fbService:FirebaseService,
    private notificationService:NotificationService,) { }

  ngOnInit(): void {
    this.chsildfieldTypes = this.fieldTypes.filter(item => item.type !== 'array');
  }

  addNewArrayItem(){
    let isValid:any = this.arrayValidation();
    if(isValid){
      let obj:any = {
        name:'',
        type:'string',
        value:'',
        timestampDate:'',
        timestampTime:'',
        geoLatitube:'',
        geoLongtitude:'',
        referenceValue:''
      }
      this.childTypeArray.push(obj);
    }else{
      this.notificationService.showError(
        'Fill All upper fields'
      );
    }
  }
  removeArrayItem(i:any){
    this.childTypeArray.splice(i ,1);
  }

  cancelFieldFun(){
    this.newFieldEvent.emit(false);
  }

  addNewField(){
    if(this.collectionName && this.documentId && this.btnValidator){
      this.btnLoader = true;
      this.btnDisabled = true;
      let formValue = this.getFormValue();
      const fieldName = formValue.fieldName;
      const fieldValue = formValue.fieldValue;
  
      this.fbService.addFieldToDocument(this.collectionName, this.documentId, fieldName, fieldValue)
        .then(() => {
          this.btnLoader = false;
          this.btnDisabled = false;
          this.isFieldAdded.emit(true);
          this.cancelFieldFun();
        })
        .catch((error) => {
          this.btnLoader = false;
          this.btnDisabled = false;
          this.cancelFieldFun();
          this.notificationService.showError(
            'Error adding field to document:'
          );
          console.error('Error adding field to document:', error);
        });
    }
  }

  parrentTypeChange(event:any){
    this.parrentSelType = event.target.value;
    if(this.parrentSelType == 'boolean'){
      this.parrentSelValue = 'true';
    }else{
      this.parrentSelValue = '';
    }
    this.timestampDate ='';
    this.timestampDate ='';
    this.geoLatitube = '';
    this.geoLongtitude = '';
  }

  getFormValue(){
    if (this.parrentSelType == 'string' || this.parrentSelType == 'number' || this.parrentSelType == 'boolean') {
      return {fieldName : this.parrentSelName , fieldValue : this.parrentSelValue};
    }else if (this.parrentSelType == 'null') {
      return {fieldName : this.parrentSelName , fieldValue : null};
    }else if (this.parrentSelType == 'timestamp') {
      return {fieldName : this.parrentSelName , fieldValue : `${this.timestampDate} at ${this.timestampTime}`};
    }else if(this.parrentSelType == 'geopoint'){
      return {fieldName : this.parrentSelName , fieldValue : `[${this.geoLatitube}°N, ${this.geoLongtitude}°E]`};
    }else if(this.parrentSelType == 'reference'){
      return {fieldName : this.parrentSelName , fieldValue : this.referenceValue};
    }else if (this.parrentSelType == 'array'){
      let arrayVal:any[]=[];
      this.childTypeArray.forEach((el:any)=>{
        if (el.type == 'string' || el.type == 'number' || el.type == 'boolean') {
          arrayVal.push(el.value)
        }else if (el.type == 'null') {
          arrayVal.push(null);
        }else if (el.type == 'timestamp') {
          arrayVal.push(`${el.timestampDate} at ${el.timestampTime}`)
        }else if(el.type == 'geopoint'){
          arrayVal.push(`[${el.geoLatitube}°N, ${el.geoLongtitude}°E]`)
        }else if(el.type == 'reference'){
          arrayVal.push(el.referenceValue)
        }
      })
      return {fieldName : this.parrentSelName , fieldValue: arrayVal}
    }
  }

  btnValidator() {
    if (this.parrentSelName && this.parrentSelName != '') {
      if (this.parrentSelType == 'string' || this.parrentSelType == 'number' || this.parrentSelType == 'boolean') {
        if (this.parrentSelValue && (this.parrentSelValue == 0 || this.parrentSelValue != '')) {
          return true;
        }
      } else if (this.parrentSelType == 'null') {
        return true;
      } else if (this.parrentSelType == 'timestamp') {
        if (this.timestampDate && this.timestampDate != '' && this.timestampTime && this.timestampTime != '') {
          return true;
        }
      }else if(this.parrentSelType == 'geopoint'){
        if(this.geoLatitube && this.geoLongtitude ){
          return true;
        }
      }else if(this.parrentSelType == 'reference'){
        if(this.referenceValue && this.referenceValue != ''){
          return true;
        }
      }else if(this.parrentSelType == 'array'){
        if(this.arrayValidation()){
          return true;
        }
      }
    }
    return false;
  }

  arrayValidation() {
    if (this.parrentSelName && this.parrentSelName != '') {
      let index = this.childTypeArray.length - 1;
      if (this.childTypeArray[index].type == 'string' || this.childTypeArray[index].type == 'number' || this.childTypeArray[index].type == 'boolean') {
        if (this.childTypeArray[index].value && (this.childTypeArray[index].value == 0 || this.childTypeArray[index].value != '')) {
          return true;
        }
      } else if (this.childTypeArray[index].type == 'null') {
        return true;
      } else if (this.childTypeArray[index].type == 'timestamp') {
        if (this.childTypeArray[index].timestampDate && this.childTypeArray[index].timestampDate != '' && this.childTypeArray[index].timestampTime && this.childTypeArray[index].timestampTime != '') {
          return true;
        }
      } else if (this.childTypeArray[index].type == 'geopoint') {
        if (this.childTypeArray[index].geoLatitube && this.childTypeArray[index].geoLongtitude) {
          return true;
        }
      } else if (this.childTypeArray[index].type == 'reference') {
        if (this.childTypeArray[index].referenceValue && this.childTypeArray[index].referenceValue != '') {
          return true;
        }
      }
      return false;
    }
  }

}
