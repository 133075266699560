import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.scss']
})
export class FirebaseComponent implements OnInit {
  isSidebarOpend:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }
  fSidebarEvent(e:boolean){
    console.log("sidebar event" , e);
    this.isSidebarOpend = e;
  }

}
