import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  constructor(private afs: AngularFirestore) {}

  async firestoreAction(
    action: string,
    collection: string,
    docId?: string,
    data?: any,
    merge?: boolean
  ): Promise<void> {
    const docRef: AngularFirestoreDocument<any> = this.afs.doc(
      `${collection}/${docId}`
    );
    switch (action) {
      case 'set':
        await docRef.set(data, { merge: merge || true });
        break;
      case 'update':
        await docRef.update(data);
        break;
      case 'delete':
        await docRef.delete();
        break;
      default:
        break;
    }
  }

  async queryFirestore(
    collection: string,
    id: string,
    field?: string
  ): Promise<any> {
    return await this.afs
      .collection(collection)
      .doc(id)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          return field ? doc.data()[field] : doc.data();
        }
        return null;
      })
      .catch(() => null);
  }
}
