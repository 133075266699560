<div class="card">
    <div class="card-header">
        <h3 class="m-0">Add an Email/Password user</h3>
    </div>
    <div class="card-body">
        <form [formGroup]="myForm">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" formControlName="email">
              <div *ngIf="myForm.get('email').hasError('required') && myForm.get('email').touched" class="text-danger">
                Email is required.
              </div>
              <div *ngIf="myForm.get('email').hasError('email') && myForm.get('email').touched" class="text-danger">
                Please enter a valid email address.
              </div>
            </div>
          
            <div class="form-group mt-2">
              <label for="password">Password</label>
              <input type="password" class="form-control" formControlName="password">
              <div *ngIf="myForm.get('password').hasError('required') && myForm.get('password').touched" class="text-danger">
                Password is required.
              </div>
              <div *ngIf="myForm.get('password').hasError('minlength') && myForm.get('password').touched" class="text-danger">
                Password must be at least 6 characters long.
              </div>
            </div>
          
            <div class="mt-3 d-flex justify-content-end">
            <button class="btn btn-cancel" (click)="close()">Cancel</button>
              <button type="submit" (click)="onSubmit()" class="btn btn-next" [disabled]="myForm.invalid || btnDisabled">
                <div *ngIf="btnLoader" class="spinner-border spinner-border-sm me-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>Add</button>
            </div>
          </form>          
    </div>
</div>