import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any | Error) => {
    if (err.message) {
      console.error(err.message);
    } else {
      console.error(err.toString());
    }

    if (err.stack) {
      console.error(err.stack);
    }
  });
