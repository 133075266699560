import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, pluck, take } from 'rxjs/operators';

export interface State {
  [key: string]: any;
}

export abstract class Store {
  private subject: BehaviorSubject<State>;
  private store: Observable<State>;
  private initialState: State;

  constructor(initialState: State) {
    this.subject = new BehaviorSubject(initialState);
    this.store = this.subject.asObservable().pipe(distinctUntilChanged());
    this.initialState = initialState;
  }

  get value(): State {
    return this.subject.value;
  }

  get = (key: string): any => {
    let value: any = null;
    this.select(key)
      .pipe(take(1))
      .subscribe((v: any) => (value = v));
    return value;
  };

  select(name: string): Observable<any> {
    return this.store.pipe(pluck(name), distinctUntilChanged());
  }

  set = (name: string, state: any) =>
    this.subject.next({ ...this.value, [name]: state });

  reset() {
    this.subject.next(this.initialState);
  }
}
