import { Injectable } from '@angular/core';

import { SortEvent } from '../../../../../shared/advanced-table/sortable.directive';

@Injectable({
  providedIn: 'root',
})
export class SortService {
  constructor() {}

  // switch to advanced-table-service.service.ts ?

  getColumnValue(data: any, column: string): any {
    return data[column];
  }

  onTableSort(event: SortEvent, sortData: any): void {
    const { column, direction } = event;
    this.sortData(column, direction, sortData);
  }

  sortData(
    column: string,
    direction: 'asc' | 'desc' | '',
    sortData: any
  ): void {
    if (direction === '' || !column) {
      return;
    }

    sortData.sort((a: any, b: any) => {
      const valueA = this.getColumnValue(a, column);
      const valueB = this.getColumnValue(b, column);
      const res = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;

      return direction === 'asc' ? res : -res;
    });
  }
}
