import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgTemplateOutlet } from '@angular/common';

import { AdvancedTableServices } from './advanced-table-service.service';
import { NgbSortableHeaderDirective, SortEvent } from './sortable.directive';
import { AdminService } from '../../core/services/admin/admin.service'
export interface Column {
  name: string;
  label: string;
  formatter: (a: any) => any | string | SafeHtml;
  sort?: boolean;
  width?: number;
}

@Component({
  selector: 'app-advanced-table',
  templateUrl: './advanced-table.component.html',
  providers: [AdvancedTableServices],
})
export class AdvancedTableComponent
  implements OnInit, OnChanges, AfterViewChecked
{
  @Input() pagination: boolean = false;
  @Input() expandedRowTemplate?: TemplateRef<any>;
  @Input() isEditable?: boolean = false;
  @Input() isExpandable?: boolean = false;
  @Input() isSearchable?: boolean = false;
  @Input() isSortable?: boolean = false;
  @Input() pageSizeOptions: number[] = [];
  @Input() tableData: any[] = [];
  @Input() tableClasses: string = '';
  @Input() theadClasses: string = '';
  @Input() hasRowSelection: boolean = false;
  @Input() columns: Column[] = [];

  collectionSize: number = this.tableData.length;
  selectAll: boolean = false;
  isSelected: boolean[] = [];
  expandedRows: boolean[] = [];
  
  @Output() handleTableLoad = new EventEmitter<any>();
  @Output() handleRowExpanded = new EventEmitter<any>();
  @Output() rowSelectionChange = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  @Output() sort = new EventEmitter<SortEvent>();
  @Output() tableDataChange = new EventEmitter<any>();
  @Output() deleteAction = new EventEmitter<any>();
  @Output() editAction = new EventEmitter<any>();
  
  @ViewChildren(NgbSortableHeaderDirective)
  headers!: QueryList<NgbSortableHeaderDirective>;
  @ViewChildren('advancedTable') advancedTable!: any;
  edit: { [key: string]: boolean }[] = [];

  constructor(public service: AdvancedTableServices,
    private adminService: AdminService
  ) { }

  ngAfterViewChecked(): void {
    this.handleTableLoad.emit();
  }

  ngOnInit(): void {
    for (let i = 0; i < this.tableData.length; i++) {
      this.edit[i] = {};
      this.isSelected[i] = false;
      this.expandedRows[i] = false;
      if (this.isEditable) {
        for (let j = 0; j < this.columns.length; j++) {
          this.edit[i][this.columns[j].name] = true;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData) {
      this.initEditObject();
    }
    this.paginate();
  }

  expandRow(index: number): void {
    const currentIndex = this.expandedRows.indexOf(true);
    if (currentIndex !== -1 && currentIndex !== index) {
      this.expandedRows[currentIndex] = false;
    }

    this.expandedRows[index] = !this.expandedRows[index];
    if (this.expandedRows[index]) {
      this.onRowExpand(index);
    }
  }

  isRowExpanded(index: number): boolean {
    return this.expandedRows[index];
  }

  onRowExpand(index: number): void {
    this.handleRowExpanded.emit(this.tableData[index]);
  }

  startEditing(row: number, column: string): void {
    this.edit[row][column] = true;
  }

  stopEditing(row: number, column: string): void {
    const updatedRecord = Object.assign({}, this.tableData[row], {
      [column]: this.tableData[row][column],
    });
    let updatedTableData = [...this.tableData];
    updatedTableData[row] = updatedRecord;
    this.tableDataChange.emit(updatedTableData);
    this.edit[row][column] = false;
  }

  initEditObject(): void {
    this.edit = this.tableData.map(() =>
      this.columns.reduce(
        (acc, column) => ({ ...acc, [column.name]: this.isEditable }),
        {}
      )
    );
  }

  getEditCellValue(
    i: number,
    page: number,
    pageSize: number,
    columnName: string
  ): boolean {
    const index = i + (page - 1) * pageSize;

    if (!this.edit) {
      return false;
    }

    if (index >= this.edit.length) {
      console.log(
        `Index ${index} is out of bounds of edit array length ${this.edit.length}.`
      );
      return false;
    }

    if (this.edit[index][columnName] === undefined) {
      console.log(
        `Property ${columnName} does not exist on the element at index ${index}.`
      );
      return false;
    }

    return Boolean(this.edit[index][columnName]);
  }

  /**
   * sets pagination configurations
   */
  paginate(): void {
    this.service.totalRecords = this.tableData.length;
    if (this.service.totalRecords === 0) {
      this.service.startIndex = 0;
    } else {
      this.service.startIndex =
        (this.service.page - 1) * this.service.pageSize + 1;
    }
    this.service.endIndex = Number(
      (this.service.page - 1) * this.service.pageSize + this.service.pageSize
    );
    if (this.service.endIndex > this.service.totalRecords) {
      this.service.endIndex = this.service.totalRecords;
    }
  }

  emitRowSelectionChange(): void {
    const selectedRows = this.tableData.filter(
      (_, index) => this.isSelected[index]
    );
    this.rowSelectionChange.emit(selectedRows);
    this.adminService.receiveSelectedRows(selectedRows);
  }

  /**
   * Search Method
   */
  searchData(): void {
    this.search.emit(this.service.searchTerm);
  }

  /**
   * sorts column
   * @param param0 column name,sort direction
   */
  onSort({ column, direction }: SortEvent): void {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

    this.sort.emit({ column, direction });
  }

  /**
   *  calls formatter function for table cells
   * @param column column name
   * @param data data of column
   */
  callFormatter(column: Column, data: any): any {
    return column.formatter(data);
  }
  deleteCall(column:any , data:any){
    this.deleteAction.emit(data);
  }
  editCall(column:any , data:any){
    this.editAction.emit(data);
  }

  /**
   * @returns intermediate status of selectAll checkbox
   */
  checkIntermediate(): boolean {
    let selectedRowCount = this.isSelected.filter((x) => x === true).length;
    let valid = false;
    if (
      !this.selectAll &&
      selectedRowCount > 0 &&
      selectedRowCount < this.tableData.length
    ) {
      valid = true;
    } else {
      valid = false;
    }
    return valid;
  }

  /**
   * select all row
   * @param event event
   */
  selectAllRow(event: any): void {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      for (let i = 0; i < this.tableData.length; i++) {
        this.isSelected[i] = true;
      }
    } else {
      for (let i = 0; i < this.tableData.length; i++) {
        this.isSelected[i] = false;
      }
    }
    this.emitRowSelectionChange();
  }

  /**
   * selects row
   * @param index row index
   */
  selectRow(index: number): void {
    this.isSelected[index] = !this.isSelected[index];
    this.selectAll =
      this.isSelected.filter((x) => x === true).length ===
      this.tableData.length;
    this.emitRowSelectionChange();
  }
}
