import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Event {
  type: string;
  payload?: any;
}

type EventCallback = (payload: any) => void;

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private handler = new Subject<Event>();
  private subscriptions: { [type: string]: Subscription } = {};

  constructor() {}

  /**
   * Broadcast the event
   * @param type type of event
   * @param payload payload
   */
  broadcast(type: string, payload: any = ''): void {
    this.handler.next({ type, payload });
  }

  /**
   * Subscribe to event
   * @param type type of event
   * @param callback call back function
   */
  subscribe(type: string, callback: EventCallback): void {
    if (this.subscriptions[type]) {
      this.subscriptions[type].unsubscribe();
    }

    this.subscriptions[type] = this.handler
      .pipe(
        filter((event) => event.type === type),
        map((event) => event.payload)
      )
      .subscribe(callback);
  }

  /**
   * Unsubscribe from a specific event
   * @param type type of event
   */
  unsubscribe(type: string): void {
    if (this.subscriptions[type]) {
      this.subscriptions[type].unsubscribe();
      delete this.subscriptions[type];
    }
  }

  /**
   * Unsubscribe from all events
   */
  unsubscribeAll(): void {
    for (let type in this.subscriptions) {
      this.subscriptions[type].unsubscribe();
    }
    this.subscriptions = {};
  }

  /**
   * Check if there's an active subscription for an event type
   * @param type type of event
   * @returns boolean
   */
  hasSubscription(type: string): boolean {
    return !!this.subscriptions[type];
  }
}
