export const environment2 = {
  production: false,
  accessKeyS3: 'AKIASF32VRIAKFEFHTUV',
  secretKeyS3: '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',
  regionS3: 'us-east-1',
  payrollBucketName: 'rpi-payrolls-test',
  googleMapsKey: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4',
  githubToken: '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',
  apiUrl: 'http://localhost:3000/v1',
  firebaseConfig : {
    apiKey: "AIzaSyAh2sTE3Y2XUg2xHH_qPRNOJcoxiYjjHfk",
    authDomain: "retirement-plans-inc-test.firebaseapp.com",
    projectId: "retirement-plans-inc-test",
    storageBucket: "retirement-plans-inc-test.appspot.com",
    messagingSenderId: "793849482594",
    appId: "1:793849482594:web:e3cd94d87e9c9afbfaf03b",
    measurementId: "G-SKW5EVLREK"
  }
};
