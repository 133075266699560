import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { PERSISTENCE_SESSION } from '../../constants/authentication';
import { UserStore } from './user.store';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserService } from './user.service';
import { NavigationService } from '../navigation/navigation.service';
import { NotificationService } from '../notification/notification.service';
import { User, UserCredential } from 'firebase/auth';
import { TokenService } from '../token/token.service';
import { AdminService } from '../admin/admin.service';

@Injectable({
  providedIn: 'root',
})
export class UserAuthenticationService {
  constructor(
    private admin: AdminService,
    private afAuth: AngularFireAuth,
    private auth: AuthenticationService,
    private nav: NavigationService,
    private notifications: NotificationService,
    private store: UserStore,
    private token: TokenService,
    private user: UserService
  ) {
    this.afAuth.setPersistence(PERSISTENCE_SESSION);
  }

  async loginWithEmail(email: string, password: string) {
    const credential: UserCredential = await this.auth
      .loginWithEmail(email, password)
      .then((cred) => cred)
      .catch(() => null);

    if (credential && credential?.user !== null) {
      await this.user.initializeUser(credential);
      await this.nav.navigateToDashboard();
      const userID = credential?.user?.uid || this.store.get('userID');
      await this.notifications.createFirestoreLoginNotification(userID);
    } else {
      await this.logout();
    }
  }

  async loginWithGoogle() {
    const credential: UserCredential = await this.auth
      .loginWithGoogle()
      .then((cred) => cred)
      .catch(() => null);

    if (credential && credential !== null) {
      await this.user.initializeUser(credential);
      await this.nav.navigateToDashboard();
      const userID = credential?.user?.uid || this.store.get('userID');
      await this.notifications.createFirestoreLoginNotification(userID);
    } else {
      await this.logout();
    }
  }

  async logout(): Promise<void> {
    // await this.notifications.createFirestoreLogoutNotification();
    await this.afAuth.signOut();
    this.user.resetUser();
    await this.nav.navigateToLogin();
  }

  async validateAuthenticatedUser(): Promise<void> {
    const user: User = this.store.get('user');
    const jwt: string = this.store.get('jwt') || (await user?.getIdToken());
    const isAuthenticated: boolean = this.auth.isAuthenticated();
    const isAccessTokenValid: boolean = this.token.isAccessTokenValid(jwt);
    const uid: string = user?.uid;
    const email: string = user?.email;

    if (!!email && !!user && !!uid && isAccessTokenValid && isAuthenticated) {
      this.nav.navigateToDashboard();
    } else {
      await this.logout();
    }
  }

  async validateAdminUser(): Promise<void> {
    const user: User = this.store.get('user');
    const jwt: string = this.store.get('jwt') || (await user?.getIdToken());
    const isAdmin: boolean = this.admin.isAdmin();
    const isAuthenticated: boolean = this.auth.isAuthenticated();
    const isAccessTokenValid: boolean = this.token.isAccessTokenValid(jwt);
    const uid: string = user?.uid;
    const email: string = user?.email;

    if (
      !!email &&
      !!user &&
      !!uid &&
      isAdmin &&
      isAuthenticated &&
      isAccessTokenValid
    ) {
      this.nav.navigateToAdminDashboard();
    } else {
      await this.logout();
    }
  }
}
