import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

import { DateService } from '../date/date.service';
import { UserService } from '../user/user.service';
import { NotificationItem } from '../../../layout/shared/models/notification.model';
import { UserStore } from '../user/user.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications$: Observable<NotificationItem[]> =
    this.store.select('notifications');

  constructor(
    private afs: AngularFirestore,
    private date: DateService,
    private snackBar: MatSnackBar,
    private user: UserService,
    private store: UserStore
  ) {}

  createFirestoreNotification(name: string): object[] {
    const welcomeMessage: string = !!name ? `Welcome ${name}` : 'Welcome';
    return [
      {
        isActive: true,
        redirectTo: '/auth/dashboard',
        text: `Welcome ${name} to the RPI401k Payroll System`,
        timestamp: this.date.getCurrentDateTimestamp(),
      },
      {
        isActive: true,
        redirectTo: '/auth/dashboard',
        text: `${welcomeMessage}! Check out the payroll portal tour before getting started.`,
        timestamp: this.date.getCurrentDateTimestamp(),
      },
    ];
  }

  async createFirestoreLoginNotification(userID: string): Promise<void> {
    if (userID) {
      const loginMessage = `Successfully logged in on ${this.date.getCurrentDateInEST()}.`;
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${userID}`
      );

      await userRef.update({
        notifications: firebase.firestore.FieldValue.arrayUnion({
          isActive: true,
          redirectTo: '/auth/dashboard',
          text: loginMessage,
          timestamp: this.date.getCurrentDateTimestamp(),
        }),
      });
    } else {
      console.error('User ID is not defined.');
    }
  }

  async createFirestoreLogoutNotification(): Promise<void> {
    const userID = this.store.get('userID');
    if (userID) {
      const logoutMessage = `Successfully logged out on ${this.date.getCurrentDateInEST()}.`;
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${userID}`
      );

      await userRef.update({
        notifications: firebase.firestore.FieldValue.arrayUnion({
          isActive: true,
          redirectTo: '/auth/login',
          text: logoutMessage,
          timestamp: this.date.getCurrentDateTimestamp(),
        }),
      });
    }
  }

  async getFirestoreUserNotifications(
    userID?: string
  ): Promise<NotificationItem[]> {
    const id: string = userID ? userID : this.store.get('userID');
    if (id) {
      return await this.afs
        .collection('users')
        .doc(id)
        ?.ref?.get()
        .then(async (doc) => {
          if (doc) {
            const userExists: boolean = doc?.exists;
            if (userExists) {
              const user: any = doc?.data();
              const notifications: any[] = user?.notifications
                ? user.notifications
                    .filter((n: any) => n.isActive)
                    .map((n: any) => {
                      const notification: NotificationItem = {
                        text: n.text,
                        // icon: 'fe-bell',
                        isActive: n.isActive,
                        // avatar?: string;
                        subText: 'Click to dismiss',
                        // bgColor: // color here
                        redirectTo: n?.redirectTo,
                      };
                      return notification;
                    })
                : [];
              this.store.set('notifications', notifications);
              return notifications;
            } else {
              return [];
            }
          }
        });
    }
  }

  async clearFirestoreNotifications(): Promise<void> {
    const user = this.store.get('user');
    if (user) {
      const notifications: NotificationItem[] =
        await this.getFirestoreUserNotifications();
      notifications.forEach((n: NotificationItem) => (n.isActive = false));
      await this.user.updateUser(user, 'notifications', notifications);
    }
  }

  async setFirestoreNotificationAsRead(item: NotificationItem): Promise<void> {
    const user = await this.store.get('user');
    if (user) {
      const notifications: NotificationItem[] =
        await this.getFirestoreUserNotifications();
      if (
        notifications?.length > 0 &&
        notifications?.some((n: NotificationItem) => n?.text === item?.text)
      ) {
        const notificationToDisableIndex = notifications.findIndex(
          (n: NotificationItem) => n?.text === item?.text
        );
        if (notificationToDisableIndex >= 0) {
          notifications[notificationToDisableIndex].isActive = false;
          await this.user.updateUser(user, 'notifications', notifications);
          this.user.set('notifications', notifications);
        }
      }
    }
  }

  /**
   * Show a notification
   * @param message The message to show
   * @param type Class of the notification ['error-notification', 'success-notification', 'info-notification', 'warning-notification']
   * @param duration Duration in milliseconds. Default is 5000 (5 seconds).
   */
  createNotification(
    message: string,
    type: string,
    duration: number = 5000
  ): void {
    this.snackBar.open(message, 'Close', {
      duration: duration,
      panelClass: [type + '-notification'],
    });
  }

  /**
   * Show an error notification
   * @param message The message to show
   * @param duration Duration in milliseconds. Default is 5000 (5 seconds).
   */
  showError(message: string, duration: number = 5000): void {
    this.createNotification(message, 'error', duration);
  }

  /**
   * Show a success notification
   * @param message The message to show
   * @param duration Duration in milliseconds. Default is 5000 (5 seconds).
   */
  showSuccess(message: string, duration: number = 5000): void {
    this.createNotification(message, 'success', duration);
  }

  /**
   * Show an info notification
   * @param message The message to show
   * @param duration Duration in milliseconds. Default is 5000 (5 seconds).
   */
  showInfo(message: string, duration: number = 5000): void {
    this.createNotification(message, 'info', duration);
  }

  /**
   * Show a warning notification
   * @param message The message to show
   * @param duration Duration in milliseconds. Default is 5000 (5 seconds).
   */
  showWarning(message: string, duration: number = 5000): void {
    this.createNotification(message, 'warning', duration);
  }
}
