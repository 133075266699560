import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { FCreateAuthUserComponent } from '../modals/f-create-auth-user/f-create-auth-user.component';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { Column } from 'src/app/shared/advanced-table/advanced-table.component';
import { TableHeadersService } from 'src/app/core/services/table-headers/table-headers.service';
import { SortService } from '../../../admin/services/sort.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-f-authentication',
  templateUrl: './f-authentication.component.html',
  styleUrls: ['./f-authentication.component.scss']
})
export class FAuthenticationComponent implements OnInit {
  allUsersList:any[]=[];
  usersData:any=[];
  page = 1;
  pageSize = 10;
  collectionSize:any;
  isLoading:boolean = true;
  searchEmailValue:string='';
  censusDataColumns: Column[] = this.tableHeadersService.FIREBASE_AUTH_USERS;
  isEditable: boolean = false;

  constructor(private fbSerice:FirebaseService,
    private dialog: MatDialog,
    private notificationService:NotificationService,
    private tableHeadersService: TableHeadersService,
    public sort: SortService) { }

  ngOnInit(): void {
    this.collectionSize = this.allUsersList.length;
    this.getAllUsers();
  }

  setEditable(): void {
    this.isEditable = !this.isEditable;
  }
  
  refreshData() {
    this.usersData = this.allUsersList
      .map((Clinic, i) => ({ id: (i + 1).toString(), ...Clinic }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  getAllUsers(){
    this.isLoading = true;
    this.fbSerice.getAllUsers().subscribe((user) => {
      this.isLoading = false;
      if(user){
        this.allUsersList = user;
        console.log("this.allUsersList" , this.allUsersList);
        this.refreshData();
      }else{
        this.notificationService.showError(
          'Failed to get users'
        );
      }
    },(error:any)=>{
      this.notificationService.showError(
        'Failed to get users'
      );
      this.isLoading = false;
      console.error('Failed to get users' , error);
    });
  }

  addNewUser(data?: any): void {
    data = {pass:'dekdedk'};
    const dialogRef = this.dialog.open(FCreateAuthUserComponent, {
      width: '500px',
      data: data,
      disableClose: true, 
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status){
        this.getAllUsers();
      }
    });
  }

  filterArrayByEmail(email: string): void {
    if(email){
      this.allUsersList = this.allUsersList.filter(item => item.email.includes(email));
      // this.refreshData();
    }else{
      this.getAllUsers();
    }
  }
  deleteFUser(event:any){
    if(event.uid){
      this.confirmDeletePlan(event.uid);
      
    }else{
      this.notificationService.showError(
        'Failed to delete this user'
      );
    }
  }

  confirmDeletePlan(uid:any): void {
    Swal.fire({
      title: 'Delete User',
      html: `
        <div class="flex flex-col">
          <p>Are you sure you want to delete this user?</p>
          <p>This action cannot be undone.</p>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Delete User',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return ;
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        this.fbSerice.deleteUser(uid).subscribe((res:any)=>{
          this.isLoading = false;
          this.getAllUsers();
          this.notificationService.showError(
            'User deleted successfully'
          );
        },(error:any)=>{
          this.isLoading = false;
        })
      }
    });
  }

}
