import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewCollectionComponent } from '../modals/new-collection/new-collection.component';
import { NewDocumentComponent } from '../modals/new-document/new-document.component';
import { FirebaseService } from '../../services/firebase.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NotificationService } from 'src/app/core/services/notification/notification.service';

@Component({
  selector: 'app-f-collections',
  templateUrl: './f-collections.component.html',
  styleUrls: ['./f-collections.component.scss']
})
export class FCollectionsComponent implements OnInit {
  isNewField:boolean=false;
  deleteCollectionBtn:boolean = false;
  deleteCollectionBtn2:boolean=false;
  deleteDocumentBtn:boolean=false;
  allDocumentsList:any[]=[];
  allCollectionsList:any[]=[];
  openedColName:string = '';
  openedDocId:string = '';
  sinCompleteDoc:any;
  sinCompleteDocProps: string[] = [];
  editableProperties: { [key: string]: boolean } = {};

  colLoader:boolean=true;
  docLoader:boolean=true;

  constructor(private dialog: MatDialog,
    private fbService:FirebaseService,
    private functions: AngularFireFunctions,
    private notificationService:NotificationService) { }

  ngOnInit(): void {
    this.getAllCollections();
  }

  getAllCollections(): void {
    this.fbService.getListOfCollections().subscribe(
      data => {
        this.colLoader = false;
        this.allCollectionsList = [];
        data.collections.forEach((el:any) => {
          this.allCollectionsList.push({name:el});
        });
        this.highlightFun();
      },
      error => {
        this.colLoader = false;
        this.notificationService.showError(
          'Error fetching collections:'
        );
        console.error('Error fetching collections:', error);
      }
    );
  }

  openCollection(name:string, index:any){
    if(this.deleteCollectionBtn){
      this.deleteCollectionBtn = false;
    }
    if(this.deleteCollectionBtn2){
      this.deleteCollectionBtn2 = false;
    }
    this.openedColName = name;
    this.openedDocId ='';
    this.allCollectionsList.map((el:any)=> el.isOpened = false);
    this.allCollectionsList[index].isOpened= true;
    this.getAllDocuments(this.openedColName);
  }

  newCollection(data?: any): void {
    data = {pass:'dekdedk'};
    const dialogRef = this.dialog.open(NewCollectionComponent, {
      width: '700px',
      data: data,
      // disableClose: true, 
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result && result.status){
        this.getAllCollections();
      }
    });
  }

  deleteCollection(){
    if(this.openedColName){
      this.deleteCollectionBtn = false;
      this.deleteCollectionBtn2 = false;
      const collectionName = this.openedColName;
      this.fbService.deleteCollection(collectionName).subscribe(
        () => {
          this.openedColName = '';
          this.getAllDocuments(this.openedColName);
        },
        (error) => {
          this.notificationService.showError(
            'Error deleting collection:'
          );
          console.error('Error deleting collection:', error);
        }
      );
    } 
  }

  newDocument(data?: any): void {
    if(this.openedColName){
      data = {collectionName: this.openedColName};
      const dialogRef = this.dialog.open(NewDocumentComponent, {
        width: '700px',
        data: data,
        // disableClose: true, 
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result && result.status){
          this.getAllCollections();
          this.openedDocId = result.documentId;
          this.highlightFun();
          this.openDocumentN();
        }
      });
    }
  }
  newFieldEventFun(e:any){
    this.isNewField= false;
  }

  getAllDocuments(collection:string){
    this.fbService.getAllDocuments(collection).subscribe(documents => {
      this.allDocumentsList = documents;
      this.docLoader = false;
    },(error:any)=>{
      this.docLoader = false;

    });
  }

  openDocument(index:any , docId:any){
    this.openedDocId = docId;
    this.allDocumentsList.map((el:any)=> el.isOpened = false);
    this.allDocumentsList[index].isOpened= true;
    this.fbService.getDocumentById(this.openedColName , docId).subscribe(document => {
      this.sinCompleteDoc = document;
      this.sinCompleteDocProps = Object.keys(document);

    });
  }
  openDocumentN(){
    this.fbService.getDocumentById(this.openedColName , this.openedDocId).subscribe(document => {
      this.sinCompleteDoc = document;
      this.sinCompleteDocProps = Object.keys(document);
    },(error:any)=>{
      this.notificationService.showError(
        'Error Open Document'
      );
    });
  }

  deleteDocument(): void {
    if(this.openedColName && this.openedDocId){
      this.deleteDocumentBtn = false;
      this.fbService.deleteDocument(this.openedColName, this.openedDocId)
        .then(() => {
          this.openedDocId = '';
          this.sinCompleteDoc = '';
          this.sinCompleteDocProps = null;
          this.getAllDocuments(this.openedColName);
        })
        .catch((error) => {
          this.notificationService.showError(
            'Error deleting document:'
          );
          console.error('Error deleting document:', error);
        });
    }
  }

  startEditing(property: string): void {
    this.sinCompleteDocProps.forEach((el:any)=>{
      this.editableProperties[el] = false;
    })
    this.editableProperties[property] = true;
  }
  updateField(property:any){
    this.editableProperties[property] = false;
    let fieldName = property;
    let fieldValue = this.sinCompleteDoc[property];
    this.fbService.addFieldToDocument(this.openedColName, this.openedDocId, fieldName, fieldValue)
        .then(() => {
          this.notificationService.showError(
            'Field updated successfully'
          );
          // this.openDocumentN();
        })
        .catch((error) => {
          this.notificationService.showError(
            'Error updating field to document:'
          );
          console.error('Error updating field to document:', error);
        });
  }

  deleteField(property: string, myObject:any) {
    console.log("property" , property);
    console.log("myObject" , myObject);
    if (myObject.hasOwnProperty(property)) {
      delete myObject[property];
    }
    console.log("myObject" , myObject);

    this.fbService.deleteFieldFromDocument(this.openedColName, this.openedDocId, property)
        .then(() => {
          this.openDocumentN();
        })
        .catch((error) => {
          this.notificationService.showError(
            'Error adding field to document:'
          );
          console.error('Error adding field to document:', error);
        });
   
  }

  addNewField(event:any){
    if(event){
      this.openDocumentN();
    }
  }

  goToHome(){
    this.openedColName =''; 
    this.openedDocId ='';
    this.allCollectionsList.map((el:any)=> el.isOpened = false);
  }

  highlightFun(){
    if(this.openedColName){
      const index = this.allCollectionsList.findIndex((item:any)=> item.name == this.openedColName);
      this.allCollectionsList.map((el:any)=> el.isOpened = false);
      this.allCollectionsList[index].isOpened= true;
    }
    if(this.openedDocId){
      const index = this.allDocumentsList.findIndex((item:any)=> item.id == this.openedDocId);
      this.allDocumentsList.map((el:any)=> el.isOpened = false);
      this.allDocumentsList[index].isOpened= true;
    }
  }

  typeOfValue(value:any){
    return typeof(value);
  }
  isGeoField(value:string){
    if (value.includes("_lat") && value.includes("_long")) {
      return true;
    }else{
      return false;
    }
  }
}
