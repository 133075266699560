<div class="modal-wrapper">
    <div class="modal-head">
        <h2>Add a document</h2>
        <div class="mt-2">
            <p class="m-0">Parent path</p>
            <p class="m-0">/collection1</p>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-6 my-4">
            <div class="form-group position-relative">
                <label for="colId" class="mb-2">Document ID ?</label>
                <input type="text" class="form-control" id="colId" [(ngModel)]="documentId">
                <button class="btn auto-fill-btn" (click)="genAutoString()">Auto-ID</button>
            </div>
        </div>
        <!-- <div class="col-8 my-4">
            <app-f-new-field></app-f-new-field>
        </div> -->
    </div>
    <div class="modals-footer">
        <div class="d-flex align-items-center justify-content-end p-4">
            <button class="btn btn-cancel" (click)="close()">Cancel</button>
            <button class="btn btn-next" [disabled]="documentId.length == 0 || btnDisabled" (click)="onAddDocumentClick()">
                <div *ngIf="btnLoader" class="spinner-border spinner-border-sm me-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>Save</button>
        </div>
    </div>
</div>