import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { first } from 'rxjs';

import { Column } from '../../../shared/advanced-table/advanced-table.component';
import { UserService } from '../user/user.service';
import { TableHeaderFormatterService } from './table-header-formatter.service';
import { AdminService } from '../admin/admin.service';

const DASH = '-';

@Injectable({
  providedIn: 'root',
})
export class TableHeadersService {
  CENSUS_TABLE_HEADERS: Column[] = [
    {
      name: 'id',
      label: 'Row ID',
      formatter: this.idFormatter.bind(this),
    },
    {
      name: 'census_id',
      label: 'Census ID',
      formatter: this.censusIdFormatter.bind(this),
    },
    {
      name: 'file_id',
      label: 'File ID',
      formatter: this.fileIdFormatter.bind(this),
    },
    {
      name: 'client',
      label: 'Client',
      formatter: this.clientFormatter.bind(this),
    },
    {
      name: 'file_name',
      label: 'File Name',
      formatter: this.filenameFormatter.bind(this),
    },
    {
      name: 'first_name',
      label: 'First Name',
      formatter: this.firstNameFormatter.bind(this),
    },
    {
      name: 'last_name',
      label: 'Last Name',
      formatter: this.lastNameFormatter.bind(this),
    },
    {
      name: 'ssn',
      label: 'SSN',
      formatter: this.ssnFormatter.bind(this),
    },
    {
      name: 'date_of_birth',
      label: 'Date of Birth',
      formatter: this.dateOfBirthFormatter.bind(this),
    },
    {
      name: 'date_of_hire_1',
      label: 'Date of Hire 1',
      formatter: this.dateOfHire1Formatter.bind(this),
    },
    {
      name: 'date_of_hire_2',
      label: 'Date of Hire 2',
      formatter: this.dateOfHire2Formatter.bind(this),
    },
    {
      name: 'date_of_termination_1',
      label: 'Date of Termination 1',
      formatter: this.dateOfTermination1Formatter.bind(this),
    },
    {
      name: 'date_of_termination_2',
      label: 'Date of Termination 2',
      formatter: this.dateOfTermination2Formatter.bind(this),
    },
    {
      name: 'service_elig_hours',
      label: 'Service Eligibility Hours',
      formatter: this.serviceEligHoursFormatter.bind(this),
    },
    {
      name: 'comp_statutory',
      label: 'Compensation Statutory',
      formatter: this.compStatutoryFormatter.bind(this),
    },
    {
      name: 'contrib_pretax',
      label: 'Contributions Pre-Tax',
      formatter: this.contribPretaxFormatter.bind(this),
    },
    {
      name: 'contrib_roth',
      label: 'Contributions Roth',
      formatter: this.contribRothFormatter.bind(this),
    },
    {
      name: 'contribution_matching',
      label: 'Contribution Matching',
      formatter: this.contributionMatchingFormatter.bind(this),
    },
    {
      name: 'contribution_safe_harbor_matching',
      label: 'Contribution Safe Harbor Matching',
      formatter: this.contributionSafeHarborMatchingFormatter.bind(this),
    },
    {
      name: 'contribution_safe_harbor_non_elective',
      label: 'Contribution Safe Harbor Non-Elective',
      formatter: this.contributionSafeHarborNonElectiveFormatter.bind(this),
    },
    {
      name: 'contribution_qaca_safe_harbor_matching',
      label: 'Contribution QACA Safe Harbor Matching',
      formatter: this.contributionQACASafeHarborMatchingFormatter.bind(this),
    },
    {
      name: 'contribution_qaca_safe_harbor_non_elective',
      label: 'QACA Safe Harbor Non-Elective Contribution',
      formatter: this.qacaSafeHarborNonElectiveFormatter.bind(this),
    },
    {
      name: 'contribution_non_elective_profit_sharing',
      label: 'Non-Elective Profit Sharing Contribution',
      formatter: this.nonElectiveProfitSharingFormatter.bind(this),
    },
    {
      name: 'officer',
      label: 'Officer',
      formatter: this.officerFormatter.bind(this),
    },
    {
      name: 'percentage_ownership',
      label: 'Percentage Ownership',
      formatter: this.percentageOwnershipFormatter.bind(this),
    },
  ];

  PAYROLL_TABLE_HEADERS: Column[] = [
    {
      name: 'id',
      label: 'Row ID',
      formatter: this.idFormatter.bind(this),
    },
    {
      name: 'file_id',
      label: 'File ID',
      formatter: this.fileIdFormatter.bind(this),
    },
    {
      name: 'client',
      label: 'Client',
      formatter: this.clientFormatter.bind(this),
    },
    {
      name: 'file_name',
      label: 'File Name',
      formatter: this.filenameFormatter.bind(this),
    },
    {
      name: 'upload_date',
      label: 'Upload Date',
      formatter: this.fileUploadDateFormatter.bind(this),
    },
    {
      name: 'uploaded_by',
      label: 'Uploaded By',
      formatter: this.fileUploadedByFormatter.bind(this),
    },
    {
      name: 'is_processed',
      label: 'Processed',
      formatter: this.isProcessedFormatter.bind(this),
    },
    {
      name: 'processed_date',
      label: 'Processed Date',
      formatter: this.processedDateFormatter.bind(this),
    },
    {
      name: 'first_name',
      label: 'First Name',
      formatter: this.firstNameFormatter.bind(this),
    },
    {
      name: 'middle_initial',
      label: 'Middle Initial',
      formatter: this.middleNameFormatter.bind(this),
    },
    {
      name: 'last_name',
      label: 'Last Name',
      formatter: this.lastNameFormatter.bind(this),
    },
    {
      name: 'ssn',
      label: 'SSN',
      formatter: this.ssnFormatter.bind(this),
    },
    {
      name: 'date_of_birth',
      label: 'Date of Birth',
      formatter: this.dateOfBirthFormatter.bind(this),
    },
    {
      name: 'date_of_hire_1',
      label: 'Date of Hire',
      formatter: this.dateOfHire1Formatter.bind(this),
    },
    {
      name: 'date_of_hire_2',
      label: 'Date of Hire 2',
      formatter: this.dateOfHire2Formatter.bind(this),
    },
    {
      name: 'date_of_termination_1',
      label: 'Date of Termination',
      formatter: this.dateOfTermination1Formatter.bind(this),
    },
    {
      name: 'date_of_termination_2',
      label: 'Date of Termination 2',
      formatter: this.dateOfTermination2Formatter.bind(this),
    },
    {
      name: 'service_elig_hours',
      label: 'Service Eligibility Hours',
      formatter: this.serviceEligHoursFormatter.bind(this),
    },
    {
      name: 'comp_statutory',
      label: 'Compensation Statutory',
      formatter: this.compStatutoryFormatter.bind(this),
    },
    {
      name: 'contrib_pretax',
      label: 'Contributions Pre-Tax',
      formatter: this.contribPretaxFormatter.bind(this),
    },
    {
      name: 'contrib_roth',
      label: 'Contributions Roth',
      formatter: this.contribRothFormatter.bind(this),
    },
    {
      name: 'contribution_matching',
      label: 'Contribution Matching',
      formatter: this.contributionMatchingFormatter.bind(this),
    },
    {
      name: 'contribution_safe_harbor_matching',
      label: 'Contribution Safe Harbor Matching',
      formatter: this.contributionSafeHarborMatchingFormatter.bind(this),
    },
    {
      name: 'contribution_safe_harbor_non_elective',
      label: 'Contribution Safe Harbor Non-Elective',
      formatter: this.contributionSafeHarborNonElectiveFormatter.bind(this),
    },
    {
      name: 'contribution_qaca_safe_harbor_matching',
      label: 'Contribution QACA Safe Harbor Matching',
      formatter: this.contributionQACASafeHarborMatchingFormatter.bind(this),
    },
    {
      name: 'contribution_qaca_safe_harbor_non_elective',
      label: 'QACA Safe Harbor Non-Elective Contribution',
      formatter: this.qacaSafeHarborNonElectiveFormatter.bind(this),
    },
    {
      name: 'contribution_non_elective_profit_sharing',
      label: 'Non-Elective Profit Sharing Contribution',
      formatter: this.nonElectiveProfitSharingFormatter.bind(this),
    },
    {
      name: 'ee_pre_tax',
      label: 'Employee Pre-Tax',
      formatter: this.eePreTaxFormatter.bind(this),
    },
    {
      name: 'ee_roth',
      label: 'Employee Roth',
      formatter: this.eeRothFormatter.bind(this),
    },
    {
      name: 'er_harbor_match',
      label: 'Employer Harbor Match',
      formatter: this.erHarborMatchFormatter.bind(this),
    },
    {
      name: 'loan_repayments',
      label: 'Loan Repayments',
      formatter: this.loanRepaymentsFormatter.bind(this),
    },
    {
      name: 'hours_worked_period_end',
      label: 'Hours Worked Period End',
      formatter: this.hoursWorkedPeriodEndFormatter.bind(this),
    },
    {
      name: 'plan_compensation_current_period',
      label: 'Plan Compensation Current Period',
      formatter: this.planCompensationCurrentPeriodFormatter.bind(this),
    },
    {
      name: 'sub_company_number',
      label: 'Sub Company Number',
      formatter: this.subCompanyNumberFormatter.bind(this),
    },
    {
      name: 'loan_401k',
      label: 'Loan 401k',
      formatter: this.loan401kFormatter.bind(this),
    },
    {
      name: 'address_1',
      label: 'Address 1',
      formatter: this.addressFormatter1.bind(this),
    },
    {
      name: 'address_2',
      label: 'Address 2',
      formatter: this.addressFormatter2.bind(this),
    },
    {
      name: 'city',
      label: 'City',
      formatter: this.cityFormatter.bind(this),
    },
    {
      name: 'state',
      label: 'State',
      formatter: this.stateFormatter.bind(this),
    },
    {
      name: 'zip_postal_code',
      label: 'ZIP/Postal Code',
      formatter: this.postalCodeFormatter.bind(this),
    },
    {
      name: 'email_address',
      label: 'Email Address',
      formatter: this.emailAddressFormatter.bind(this),
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      formatter: this.mobilePhoneFormatter.bind(this),
    },
    {
      name: 'status',
      label: 'Status',
      formatter: this.statusFormatter.bind(this),
    },
  ];

  INBOUND_FILES_TABLE_HEADERS: Column[] = [
    {
      label: 'File ID',
      name: 'file_id',
      formatter: this.fileIdFormatter.bind(this),
      sort: true,
    },
    {
      label: 'File Amount',
      name: 'file_amount',
      formatter: this.fileAmountFormatter.bind(this),
      sort: true,
    },
    {
      label: 'File Name',
      name: 'file_name',
      formatter: this.filenameFormatter.bind(this),
      sort: true,
    },
    {
      label: 'Upload Date',
      name: 'upload_date',
      formatter: this.fileUploadDateFormatter.bind(this),
      sort: true,
    },
    {
      label: 'Uploaded By',
      name: 'uploaded_by',
      formatter: this.fileUploadedByFormatter.bind(this),
      sort: true,
    },
  ];

  OUTBOUND_FILES_TABLE_HEADERS: Column[] = [
    {
      label: 'S3 Directory',
      name: 'Key',
      formatter: this.s3FileDirectoryFormatter.bind(this),
      sort: true,
    },
    {
      label: 'S3 Filename',
      name: 'Key',
      formatter: this.s3FilenameFormatter.bind(this),
      sort: true,
    },
    {
      label: 'S3 File Size',
      name: 'Size',
      formatter: this.s3FileSizeFormatter.bind(this),
      sort: true,
    },
    {
      label: 'S3 Generated Timestamp',
      name: 'Timestamp',
      formatter: this.s3TimestampFormatter.bind(this),
      sort: true,
    },
    {
      label: 'Confirmation Email',
      name: 'file_id',
      formatter: this.sendConfirmationEmailFormatter.bind(this),
      sort: false,
    },
    {
      label: 'Download File',
      name: 'file_id',
      formatter: this.fileDownloadFormatter.bind(this),
      sort: false,
    },
    {
      label: 'Regenerate File',
      name: 'file_id',
      formatter: this.fileReuploadFormatter.bind(this),
      sort: false,
    },
  ];

  EMPLOYEE_DASHBOARD_FILE_UPLOAD_TABLE_HEADERS: Column[] = [
    {
      label: 'File ID',
      name: 'file_id',
      formatter: this.fileIdFormatter.bind(this),
      sort: true,
    },
    {
      label: 'File Name',
      name: 'file_name',
      formatter: this.filenameFormatter.bind(this),
      sort: true,
    },
    {
      label: 'Upload Date',
      name: 'upload_date',
      formatter: this.fileUploadDateFormatter.bind(this),
      sort: true,
    },
    {
      label: 'Uploaded By',
      name: 'uploaded_by',
      formatter: this.fileUploadedByFormatter.bind(this),
      sort: true,
    },
  ];

  SFTP_FILE_COLUMNS: Column[] = [
    {
      label: 'File ID',
      name: 'fileId',
      formatter: this.fileIdFormatter.bind(this),
      sort: true,
    },
    {
      label: 'File Name',
      name: 'filename',
      formatter: this.filenameFormatter.bind(this),
      sort: true,
    },
    {
      label: 'File Size',
      name: 'fileSize',
      formatter: this.fileSizeFormatter.bind(this),
      sort: true,
    },
    {
      label: 'File Type',
      name: 'fileType',
      formatter: this.fileTypeFormatter.bind(this),
      sort: true,
    },
    {
      label: 'Upload Date',
      name: 'dateUploaded',
      formatter: this.fileUploadDateFormatter.bind(this),
      sort: true,
    },
  ];

  FIREBASE_AUTH_USERS: Column[] = [
    {
      name: 'email',
      label: 'Identifier',
      formatter: this.fAuthIdentifier.bind(this),
    },
    {
      name: 'email',
      label: 'Providers',
      formatter: this.fAuthProviders.bind(this),
    },
    {
      name: 'created',
      label: 'Created',
      formatter: this.fAuthCreated.bind(this),
    },
    {
      name: 'updated',
      label: 'Signed In',
      formatter: this.fAuthSigned.bind(this),
    },
    {
      name: 'uid',
      label: 'User UID',
      formatter: this.fAuthUID.bind(this),
    },
    {
      name: 'delete_fu_auth_uid',
      label: 'Delete',
      formatter: this.fAuthDelete.bind(this),
      sort: false,
    },
  ];

  FIREBASE_GLOBAL_HEADERS: Column[] = [
    {
      name: 'displayValue',
      label: 'Display Value',
      formatter: this.fGlobalHeaderDisValue.bind(this),
    },
    {
      name: 'dbValue',
      label: 'DB Value',
      formatter: this.fGlobalHeaderDBValue.bind(this),
    },
    {
      name: 'mappingValues',
      label: 'Mapping Values',
      formatter: this.fGlobalHeaderMappingValue.bind(this),
    },
    {
      name: 'edit_f_global_header',
      label: 'Edit',
      formatter: this.fGlobalHeaderEdit.bind(this),
      sort: false,
    },
    {
      name: 'delete_f_global_header',
      label: 'Delete',
      formatter: this.fGlobalHeaderDelete.bind(this),
      sort: false,
    },
  ];

  PLANS_DASHBOARD_COLUMNS: Column[] = [
    {
      name: 'id',
      label: 'Plan ID',
      formatter: this.fPlanID.bind(this),
    },
    {
      name: 'subId',
      label: 'Plan Sub ID',
      formatter: this.fPlanSubID.bind(this),
    },
    {
      name: 'label',
      label: 'Plan Label',
      formatter: this.fPlanLabel.bind(this),
    },
    {
      name: 'name',
      label: 'Plan Name',
      formatter: this.fPlanName.bind(this),
    },
    {
      name: 'achNumber',
      label: 'ACH Number',
      formatter: this.fAchNumber.bind(this),
    },
    {
      name: 'isUsingGlobalParser',
      label: 'Using Global Parser',
      formatter: this.fUsingGlobalParser.bind(this),
    },
    {
      name: 'isCensusParsingAllowed',
      label: 'Census Parsing Allowed',
      formatter: this.fCensusParsingAllowed.bind(this),
    },
    {
      name: 'isPayrollParsingAllowed',
      label: 'Payroll Parsing Allowed',
      formatter: this.fPayrollParsingAllowed.bind(this),
    },
    {
      name: 'isMasterPlan',
      label: 'Master Plan',
      formatter: this.fMasterPlan.bind(this),
    },
    {
      name: 'isTrowepricePlan',
      label: 'T. Rowe Price Plan',
      formatter: this.fTrowePricePlan.bind(this),
    },
    {
      name: 'isTransamericaPlan',
      label: 'Transamerica Plan',
      formatter: this.fTransamericaPlan.bind(this),
    },
  ];

  PROVIDERS_DASHBOARD_COLUMNS: Column[] = [
    {
      name: 'id',
      label: 'Provider ID',
      formatter: this.fPlanID.bind(this),
    },
    {
      name: 'label',
      label: 'Provider Label',
      formatter: this.fPlanLabel.bind(this),
    },
    {
      name: 'name',
      label: 'Provider Name',
      formatter: this.fPlanName.bind(this),
    },
  ];

  USERS_DASHBOARD_COLUMNS: Column[] = [
    {
      name: 'uid',
      label: 'User ID',
      formatter: this.fAuthUID.bind(this),
    },
    {
      name: 'email',
      label: 'Email',
      formatter: this.fAuthIdentifier.bind(this),
    },
    {
      name: 'created',
      label: 'Created',
      formatter: this.fAuthCreated.bind(this),
    },
    {
      name: 'updated',
      label: 'Signed In',
      formatter: this.fAuthSigned.bind(this),
    },
  ];

  fPlanID(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.id || DASH}</p>`
    );
  }

  fPlanSubID(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.subId || DASH}</p>`
    );
  }

  fPlanLabel(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.label || DASH}</p>`
    );
  }

  fPlanName(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.name || DASH}</p>`
    );
  }

  fAchNumber(data: any): SafeHtml {
    const achNumber: string = data?.achNumber
      ? data.achNumber.slice(-4).padStart(data.achNumber.length, '*')
      : DASH;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${achNumber || DASH}</p>`
    );
  }

  fUsingGlobalParser(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.isUsingGlobalParser || false}</p>`
    );
  }

  fCensusParsingAllowed(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.isCensusParsingAllowed || false}</p>`
    );
  }

  fPayrollParsingAllowed(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.isPayrollParsingAllowed || false}</p>`
    );
  }

  fMasterPlan(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.isMasterPlan || false}</p>`
    );
  }

  fTrowePricePlan(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.isTrowepricePlan || false}</p>`
    );
  }

  fTransamericaPlan(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.isTransamericaPlan || false}</p>`
    );
  }

  fGlobalHeaderDisValue(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.displayValue || DASH}</p>`
    );
  }

  fGlobalHeaderDBValue(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.dbValue || DASH}</p>`
    );
  }

  fGlobalHeaderMappingValue(data: any): SafeHtml {
    let mapValues: string;
    if (data.mappingValues.length >= 3) {
      mapValues = data.mappingValues.slice(0, 3).join(', ');
    } else {
      mapValues = data.mappingValues.join(', ');
    }
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${mapValues || DASH}</p>`
    );
  }

  fGlobalHeaderEdit(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-primary" (click)="deleteFAuthUser(${data})">Edit</button>`
    );
  }
  fGlobalHeaderDelete(data: any): SafeHtml {
    const docId: string = data?.id ? data.id : '';
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-danger" (click)="deleteFAuthUser(${docId})">Delete</button>`
    );
  }

  constructor(
    private sanitizer: DomSanitizer,
    private admin: AdminService,
    private tableHeaderFormatterService: TableHeaderFormatterService,
    private user: UserService
  ) {}

  fAuthIdentifier(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.email || DASH}</p>`
    );
  }

  fAuthProviders(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<i class="fa fa-envelope"></i>`
    );
  }

  fAuthCreated(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.created || DASH}</p>`
    );
  }

  fAuthSigned(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.updated || DASH}</p>`
    );
  }

  fAuthUID(data: any): SafeHtml {
    const userId: string = data?.uid ? data.uid : '';
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${userId || DASH}</p>`
    );
  }

  fAuthDelete(data: any): SafeHtml {
    const userId: string = data?.uid ? data.uid : '';
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-danger" (click)="deleteFAuthUser(${userId})">Delete</button>`
    );
  }

  getClientName(data: any): string {
    return data?.client_name ? data.client_name : data.clientName || DASH;
  }

  addressFormatter1(data: any): SafeHtml {
    const address1: string = data?.address_1 || data?.address1;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${address1 || DASH}</p>`
    );
  }

  addressFormatter2(data: any): SafeHtml {
    const address2: string = data?.address_2 || data?.address2;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${address2 || DASH}</p>`
    );
  }

  censusIdFormatter(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.census_id || DASH}</p>`
    );
  }

  clientFormatter(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${this.getClientName(data)}</p>`
    );
  }

  compStatutoryFormatter(data: any): SafeHtml {
    const compStatutory: string = data?.comp_statutory
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.comp_statutory
        )
      : data.compStatutory;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${compStatutory || DASH}</p>`
    );
  }

  contribPretaxFormatter(data: any): SafeHtml {
    const contribPretax: string = data?.contrib_pretax
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.contrib_pretax
        )
      : data.contribPretax;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${contribPretax || DASH}</p>`
    );
  }

  contribRothFormatter(data: any): SafeHtml {
    const contribRoth: string = data?.contrib_roth
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.contrib_roth
        )
      : data.contribRoth;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${contribRoth || DASH}</p>`
    );
  }

  contributionMatchingFormatter(data: any): SafeHtml {
    const contributionMatching: string = data?.contribution_matching
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.contribution_matching
        )
      : data.contributionMatching;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${contributionMatching || DASH}</p>`
    );
  }

  contributionQACASafeHarborMatchingFormatter(data: any): SafeHtml {
    const contributionQACASafeHarborMatching: string =
      data?.contribution_qaca_safe_harbor_matching
        ? this.tableHeaderFormatterService.convertToDollarString(
            data.contribution_qaca_safe_harbor_matching
          )
        : data.contributionQacaSafeHarborMatching;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${contributionQACASafeHarborMatching || DASH}</p>`
    );
  }

  contributionSafeHarborMatchingFormatter(data: any): SafeHtml {
    const contributionSafeHarborMatching: string =
      data?.contribution_safe_harbor_matching
        ? this.tableHeaderFormatterService.convertToDollarString(
            data.contribution_safe_harbor_matching
          )
        : data.contributionSafeHarborMatching;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${contributionSafeHarborMatching || DASH}</p>`
    );
  }

  contributionSafeHarborNonElectiveFormatter(data: any): SafeHtml {
    const contributionSafeHarborNonElective: string =
      data?.contribution_safe_harbor_non_elective
        ? this.tableHeaderFormatterService.convertToDollarString(
            data.contribution_safe_harbor_non_elective
          )
        : data.contributionSafeHarborNonElective;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${contributionSafeHarborNonElective || DASH}</p>`
    );
  }

  dateOfBirthFormatter(data: any): SafeHtml {
    const dateOfBirth: string = data?.date_of_birth
      ? data.date_of_birth
      : data.dateOfBirth;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${dateOfBirth || DASH}</p>`
    );
  }

  dateOfHire1Formatter(data: any): SafeHtml {
    const dateOfHire1: string = data?.date_of_hire_1 || data?.date_of_hire;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${dateOfHire1 || DASH}</p>`
    );
  }

  dateOfHire2Formatter(data: any): SafeHtml {
    const dateOfHire2: string = data?.date_of_hire_2;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${dateOfHire2 || DASH}</p>`
    );
  }

  dateOfTermination1Formatter(data: any): SafeHtml {
    const dateOfTermination1: string = data?.date_of_termination_1
      ? data.date_of_termination_1
      : data.dateOfTermination1;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${dateOfTermination1 || DASH}</p>`
    );
  }

  dateOfTermination2Formatter(data: any): SafeHtml {
    const dateOfTermination2: string = data?.date_of_termination_2
      ? data.date_of_termination_2
      : data.dateOfTermination2;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${dateOfTermination2 || DASH}</p>`
    );
  }

  emailAddressFormatter(data: any): SafeHtml {
    const emailAddress: string =
      data?.email_address || data.email || data.home_email_address;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${emailAddress || DASH}</p>`
    );
  }

  eePreTaxFormatter(data: any): SafeHtml {
    const eePreTax: string = data?.ee_pre_tax
      ? this.tableHeaderFormatterService.convertToDollarString(data.ee_pre_tax)
      : data?.ee_pretax_catch_up
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.ee_pretax_catch_up
        )
      : data.eePreTax;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${eePreTax || DASH}</p>`
    );
  }

  eeRothFormatter(data: any): SafeHtml {
    const eeRoth: string = data?.ee_roth
      ? this.tableHeaderFormatterService.convertToDollarString(data.ee_roth)
      : data?.ee_roth_catch_up
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.ee_roth_catch_up
        )
      : data.eeRoth;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${eeRoth || DASH}</p>`
    );
  }

  erHarborMatchFormatter(data: any): SafeHtml {
    const erHarborMatch: string = data?.erHarborMatch
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.erHarborMatch
        )
      : data?.contribution_matching
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.contribution_matching
        )
      : data.erHarborMatch;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${erHarborMatch || DASH}</p>`
    );
  }

  fileDownloadFormatter(data: any): SafeHtml {
    const fileId: string = data?.file_id ? data.file_id : data.fileId;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-primary" type="button" (click)="downloadCSV('${fileId}')">Download</button>`
    );
  }

  downloadCSV(fileId: string): void {
    this.admin.downloadCSV(fileId).pipe(first()).subscribe();
  }

  fileAmountFormatter(data: any): SafeHtml {
    const fileAmount: string = data?.file_amount
      ? data.file_amount
      : data.fileAmount;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${fileAmount || DASH}</p>`
    );
  }

  fileUrlFormatter(data: any): SafeHtml {
    const fileUrl: string = data?.file_url ? data.file_url : data.fileUrl;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-primary" href="${fileUrl}">Open</button>`
    );
  }

  fileSizeFormatter(data: any): SafeHtml {
    const fileSize: string = data?.file_size ? data.file_size : data.fileSize;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${fileSize || DASH}</p>`
    );
  }

  fileTypeFormatter(data: any): SafeHtml {
    const fileType: string = data?.file_type ? data.file_type : data.fileType;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${fileType || DASH}</p>`
    );
  }

  fileIdFormatter(data: any): SafeHtml {
    const fileId: string = data?.file_id ? data.file_id : data.fileId;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${fileId || DASH}</p>`
    );
  }

  filenameFormatter(data: any): SafeHtml {
    const filename: string = data?.file_name ? data.file_name : data.filename;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${filename || DASH}</p>`
    );
  }

  fileReuploadFormatter(data: any): SafeHtml {
    const fileId: string = data?.file_id ? data.file_id : data.fileId;
    const filename: string = data?.file_name ? data.file_name : data.filename;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-warning" (click)="reuploadCSV(${fileId}, ${filename})">Reupload</button>`
    );
  }

  fileDeleteFormatter(data: any): SafeHtml {
    const fileId: string = data?.file_id ? data.file_id : data.fileId;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-danger" (click)="deleteCSV(${fileId})">Delete</button>`
    );
  }

  fileUploadDateFormatter(data: any): SafeHtml {
    const uploadDate: string =
      data?.upload_date || data.uploadDate || data.dateUploaded;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${uploadDate || DASH}</p>`
    );
  }

  fileUploadedByFormatter(data: any): SafeHtml {
    const uploadedBy: string = data?.uploaded_by
      ? data.uploaded_by
      : data.uploadedBy;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${uploadedBy || DASH}</p>`
    );
  }

  firstNameFormatter(data: any): SafeHtml {
    const firstName: string = data?.first_name
      ? data.first_name
      : data.firstName;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${firstName || DASH}</p>`
    );
  }

  hoursWorkedPeriodEndFormatter(data: any): SafeHtml {
    const hoursWorkedPeriodEnd: string = data?.hoursWorkedPeriodEnd
      ? data.hoursWorkedPeriodEnd
      : data?.service_elig_hours
      ? data.service_elig_hours
      : data.hoursWorkedPeriodEnd;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${hoursWorkedPeriodEnd || DASH}</p>`
    );
  }

  idFormatter(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.id || DASH}</p>`
    );
  }

  isProcessedFormatter(data: any): SafeHtml {
    let htmlString = '';

    if (data?.is_processed) {
      htmlString = '<i class="fa fa-check-circle text-success"></i>';
    } else {
      htmlString = '<i class="fa fa-times-circle text-danger"></i>';
    }

    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  processedDateFormatter(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.processed_date || DASH}</p>`
    );
  }

  lastNameFormatter(data: any): SafeHtml {
    const lastName: string = data?.last_name || data.last_name || data?.last || '';
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${lastName || DASH}</p>`
    );
  }

  loanRepaymentsFormatter(data: any): SafeHtml {
    const loanRepayments: string = data?.loan_repayments
      ? this.tableHeaderFormatterService.convertToDollarString(
          data.loan_repayments
        )
      : data.loanRepayments;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${loanRepayments || DASH}</p>`
    );
  }

  middleNameFormatter(data: any): SafeHtml {
    const middleName: string =
      data?.middle_initial || data.middle_name || data.middleInitial;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${middleName || DASH}</p>`
    );
  }

  nonElectiveProfitSharingFormatter(data: any): SafeHtml {
    const nonElectiveProfitSharing: string =
      data?.contribution_non_elective_profit_sharing
        ? this.tableHeaderFormatterService.convertToDollarString(
            data.contribution_non_elective_profit_sharing
          )
        : data.contributionNonElectiveProfitSharing;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${nonElectiveProfitSharing || DASH}</p>`
    );
  }

  officerFormatter(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.officer || DASH}</p>`
    );
  }

  percentageOwnershipFormatter(data: any): SafeHtml {
    const percentageOwnership: string = data?.percentage_ownership
      ? data.percentage_ownership
      : data.percentageOwnership;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${percentageOwnership || DASH}</p>`
    );
  }

  planCompensationCurrentPeriodFormatter(data: any): SafeHtml {
    const planCompensationCurrentPeriod: string =
      data?.plan_compensation_current_period
        ? this.tableHeaderFormatterService.convertToDollarString(
            data.plan_compensation_current_period
          )
        : data?.comp_statutory
        ? this.tableHeaderFormatterService.convertToDollarString(
            data.comp_statutory
          )
        : data.planCompensationCurrentPeriod;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${planCompensationCurrentPeriod || DASH}</p>`
    );
  }

  postalCodeFormatter(data: any): SafeHtml {
    let postalCode: string =
      data?.postal_code || data.zip_code || data.postalCode;
    if (postalCode?.length > 5) {
      postalCode = postalCode.slice(0, 5);
    }
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${postalCode || DASH}</p>`
    );
  }

  qacaSafeHarborNonElectiveFormatter(data: any): SafeHtml {
    const qacaSafeHarborNonElective: string =
      data?.contribution_qaca_safe_harbor_non_elective
        ? this.tableHeaderFormatterService.convertToDollarString(
            data.contribution_qaca_safe_harbor_non_elective
          )
        : data.contributionQacaSafeHarborNonElective;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${qacaSafeHarborNonElective || DASH}</p>`
    );
  }

  sendConfirmationEmailFormatter(data: any): SafeHtml {
    const email: string = this.user.getEmail();
    const fileId: string = data?.file_id ? data.file_id : data.fileId;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<button class="btn btn-primary" (click)="sendConfirmationEmail(${email}, ${fileId})">Send</button>`
    );
  }

  serviceEligHoursFormatter(data: any): SafeHtml {
    const serviceEligHours: string = data?.service_elig_hours
      ? data.service_elig_hours
      : data.serviceEligHours;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${serviceEligHours || DASH}</p>`
    );
  }

  ssnFormatter(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.ssn || DASH}</p>`
    );
  }

  stateFormatter(data: any): SafeHtml {
    const stateProvince: string = data?.state
      ? data.state
      : data.state_province;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${stateProvince || DASH}</p>`
    );
  }

  subCompanyNumberFormatter(data: any): SafeHtml { 
    const subCompanyNumber: string = data?.sub_company_number
      ? data.sub_company_number
      : data?.subCompanyNumber ? data.subCompanyNumber : data?.sda;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${subCompanyNumber || DASH}</p>`
    );
  }

  loan401kFormatter(data: any): SafeHtml {
    const loan401k1: string = data?.loan_401k_1
      ? this.tableHeaderFormatterService.convertToDollarString(data.loan_401k_1)
      : data?.loan_401k
      ? this.tableHeaderFormatterService.convertToDollarString(data.loan_401k)
      : data.loan401k1;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${loan401k1 || DASH}</p>`
    );
  }

  address1Formatter(data: any): SafeHtml {
    const address1: string = data?.address_1 ? data.address_1 : data.address1;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${address1 || DASH}</p>`
    );
  }

  address2Formatter(data: any): SafeHtml {
    const address2: string = data?.address_2 ? data.address_2 : data.address2;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${address2 || DASH}</p>`
    );
  }

  cityFormatter(data: any): SafeHtml {
    const city: string = data?.city ? data.city : data.town;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${city || DASH}</p>`
    );
  }

  homePhoneNumberFormatter(data: any): SafeHtml {
    const homePhoneNumber: string = data?.home_phone_number
      ? data.home_phone_number
      : data.homePhoneNumber;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${homePhoneNumber || DASH}</p>`
    );
  }

  mobilePhoneFormatter(data: any): SafeHtml {
    const mobilePhone: string = data?.mobile_phone
      ? data.mobile_phone
      : data.mobilePhone;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${mobilePhone || DASH}</p>`
    );
  }

  homeEmailFormatter(data: any): SafeHtml {
    const homeEmail: string = data?.home_email
      ? data.home_email
      : data.homeEmail;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${homeEmail || DASH}</p>`
    );
  }

  clientIdNameFormatter(data: any): SafeHtml {
    const clientIdName: string = data?.client_id_name
      ? data.client_id_name
      : data.clientIdName;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${clientIdName || DASH}</p>`
    );
  }

  statusFormatter(data: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${data?.status || DASH}</p>`
    );
  }

  s3FileSizeFormatter(data: any): SafeHtml {
    const fileSize = data?.Size;
    const fileSizeInMB = `${fileSize / 1000} MB`;
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${fileSizeInMB || DASH}</p>`
    );
  }

  s3FileDirectoryFormatter(data: any): SafeHtml {
    const key = data?.Key?.split('/');
    const directory = key[0] + '/' + key[1] + '/' + key[2];
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${directory || DASH}</p>`
    );
  }

  s3FilenameFormatter(data: any, employerName: string): SafeHtml {
    const filename = data?.Key?.split('/')[3];
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${filename || DASH}</p>`
    );
  }

  s3TimestampFormatter(data: any): SafeHtml {
    const date = new Date(data?.LastModified);
    const easternDateTime = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/New_York',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date);
    return this.sanitizer.bypassSecurityTrustHtml(
      `<p class="color-black">${easternDateTime || DASH}</p>`
    );
  }
}
