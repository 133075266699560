import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirebaseComponent } from './firebase/firebase.component';
import { FCollectionsComponent } from './components/f-collections/f-collections.component';
import { FAuthenticationComponent } from './components/f-authentication/f-authentication.component';

const routes: Routes = [
  {
    path: '',
    component: FirebaseComponent,
    children: [
      {
        path: '',
        component: FCollectionsComponent,
      },
      // {
      //   path:'global-parser-mappings',
      //   component: FGlobalParserMappings
      // },
      {
        path: 'firestore',
        component: FCollectionsComponent,
      },
      {
        path: 'users',
        component: FAuthenticationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FirebaseRoutingModule {}
