<ng-container
  *ngIf="{
    user: user$ | async
  } as data">
  <div class="navbar-custom">
    <div class="container-fluid">
      <ul class="list-unstyled topnav-menu float-end mb-0">
        <li class="dropdown d-none d-lg-inline-block">
          <a
            class="nav-link dropdown-toggle arrow-none waves-effect waves-light maximize-icon"
            href="javascript:void(0)"
            (click)="toggleFullScreen()">
            <i class="fe-maximize noti-icon"></i>
          </a>
        </li>

        <li class="notification-list topbar-dropdown" ngbDropdown>
          <a
            class="nav-link waves-effect waves-light"
            href="javascript:void(0)"
            role="button"
            ngbDropdownToggle>
            <i class="fe-bell noti-icon"></i>
            <span class="badge bg-danger rounded-circle noti-icon-badge">{{
              notificationList?.length || 0
            }}</span>
          </a>
          <div class="dropdown-menu-end dropdown-lg" ngbDropdownMenu>
            <div class="noti-title" ngbDropdownItem>
              <h5 class="m-0">
                <span class="float-end">
                  <a (click)="clearNotifications()" class="text-dark">
                    <small>Clear All</small>
                  </a> </span
                >Notification
              </h5>
            </div>

            <ngx-simplebar class="noti-scroll">
              <a
                (click)="markItemAsRead(item)"
                [routerLink]="[]"
                class="notify-item"
                [class.active]="item.isActive"
                *ngFor="let item of notificationList"
                ngbDropdownItem>
                <div class="notify-icon" *ngIf="item.avatar">
                  <img
                    [src]="item.avatar"
                    class="img-fluid rounded-circle"
                    alt="" />
                </div>
                <div
                  class="notify-icon bg-{{ item.bgColor }}"
                  *ngIf="item.icon">
                  <i [class]="item.icon"></i>
                </div>
                <p class="notify-details">{{ item.text }}</p>
                <p class="text-muted mb-0 user-msg">
                  <small>{{ item.subText }}</small>
                </p>
              </a>
            </ngx-simplebar>

            <a
              routerLink="/admin/notifications"
              class="dropdown-item text-center text-primary notify-item notify-all">
              View All
              <i class="fe-arrow-right"></i>
            </a>
          </div>
        </li>

        <!-- admin menu -->
        <!-- <li class="d-none d-lg-inline-block topbar-dropdown" ngbDropdown>
                  <a class="nav-link arrow-none waves-effect waves-light" href="javascript:void(0)" ngbDropdownToggle
                      role="button">
                      <i class="fe-grid noti-icon"></i>
                  </a>
                  <div class="dropdown-lg dropdown-menu-end" ngbDropdownMenu>
                      <div class="p-lg-1">
                          <div class="row g-0 row-cols-3">
                              <div class="col" *ngFor="let brand of brands">
                                  <a class="dropdown-icon-item" href="javascript:void(0)">
                                      <img [src]="brand.logo" [alt]="brand.name">
                                      <span>{{brand.name}}</span>
                                  </a>
                              </div>
                          </div>

                      </div>

                  </div>
              </li> -->

        <li class="dropdown notification-list topbar-dropdown" ngbDropdown>
          <a
            ngbDropdownToggle
            class="nav-link nav-user me-0 waves-effect waves-light"
            id="profileMenuDropdown"
            href="javascript:void(0)"
            aria-expanded="false">
            <span class="account-user-avatar">
              <i alt="user-image" class="fe-user"></i>
            </span>
            <span class="pro-user-name ms-1">
              <i class="mdi mdi-chevron-down"></i>
            </span>
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="profileMenuDropdown"
            class="dropdown-menu-end profile-dropdown">
            <ng-container *ngIf="data?.user?.name">
              <div ngbDropdownItem class="dropdown-header noti-title">
                <h6 class="text-overflow m-0">
                  Welcome {{ data?.user?.name }}!
                </h6>
              </div>
            </ng-container>
            <a
              *ngFor="let option of profileOptions"
              class="notify-item"
              ngbDropdownItem>
              <i class="{{ option.icon }} me-1"></i>
              <span style="cursor: pointer" [routerLink]="option.redirectTo">{{
                option.label
              }}</span>
            </a>
          </div>
        </li>
      </ul>

      <div class="logo-box bg-white border-bottom border-primary">
        <a [routerLink]="'/auth/dashboard'" class="logo logo-dark text-center">
          <span class="logo-sm">
            <img src="../assets/images/logo.png" alt="logo" height="25" />
          </span>
          <span class="logo-lg">
            <img
              src="../assets/images/logo.png"
              alt="logo"
              height="50"
              *ngIf="layoutType !== 'two-column'" />
            <img
              src="../assets/images/logo.png"
              alt="logo"
              height="50"
              *ngIf="layoutType === 'two-column'" />
          </span>
        </a>

        <a [routerLink]="'/auth/dashboard'" class="logo logo-light text-center">
          <span class="logo-sm">
            <img src="../assets/images/logo.png" alt="logo" height="25" />
          </span>
          <span class="logo-lg">
            <img
              src="../assets/images/logo.png"
              alt="logo"
              height="50"
              *ngIf="layoutType !== 'two-column'" />
            <img
              src="../assets/images/logo.png"
              alt="logo"
              height="50"
              *ngIf="layoutType === 'two-column'" />
          </span>
        </a>
      </div>

      <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
        <li>
          <button
            class="button-menu-mobile waves-effect waves-light d-none d-lg-block"
            (click)="changeSidebarWidth()"
            *ngIf="layoutType !== 'horizontal'">
            <i class="fe-menu"></i>
          </button>
        </li>

        <li>
          <button
            class="button-menu-mobile open-left d-block d-lg-none waves-effect waves-light"
            *ngIf="layoutType !== 'horizontal'"
            (click)="toggleMobileMenu($event)">
            <i class="fe-menu"></i>
          </button>
        </li>

        <li>
          <a
            class="navbar-toggle nav-link"
            [ngClass]="{ open: topnavCollapsed }"
            (click)="toggleMobileMenu($event)"
            *ngIf="layoutType === 'horizontal'">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </li>
      </ul>

      <div class="clearfix"></div>
    </div>
  </div>
</ng-container>
