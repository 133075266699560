<div class="sidebar-wrapper {{ !isSidebarFull ? 'sidebar-closed' : '' }}">
  <div class="sidebar-top {{ isSidebarFull ? 'text-center' : '' }}">
    <img
      *ngIf="isSidebarFull"
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/firebase-logo.png'
        )
      "
      class="kfc-logo img-fluid" />
    <img
      *ngIf="!isSidebarFull"
      [src]="
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/images/firebase_28dp.png'
        )
      "
      class="kfc-logo img-fluid" />
  </div>
  <div class="sidebar-body">
    <ul>
      <li
        [routerLinkActive]="['active']"
        (click)="isFirstLoad = false"
        routerLink="/admin/dashboard/firebase/users">
        <i class="fa fa-users"></i>
        <span *ngIf="isContentShow">Authentication</span>
      </li>
      <li
        [routerLinkActive]="['active']"
        [class.active]="isFirstLoad"
        routerLink="/admin/dashboard/firebase/firestore">
        <i class="fas fa-angle-double-up"></i>
        <span *ngIf="isContentShow">Firestore Database</span>
      </li>
      <li
        [routerLinkActive]="['active']"
        (click)="isFirstLoad = false"
        routerLink="/admin/dashboard/firebase/global-parser-mappings">
        <i class="fas fa-map-marked-alt"></i>
        <span *ngIf="isContentShow">Global Parser Mappings</span>
      </li>
    </ul>
  </div>
  <div class="sidebar-footer float-end">
    <button class="btn collaps-btn" (click)="collapsSidebar()">
      <i *ngIf="isSidebarFull" class="fa fa-angle-left"></i>
      <i *ngIf="!isSidebarFull" class="fa fa-angle-right"></i>
    </button>
  </div>
</div>
