import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

export class ReloadInterceptor implements RouteReuseStrategy {
  private storedRoutes: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Determine which routes should be detachable
    return true; // or some condition based on the route
  }

  store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle
  ): void {
    if (route.routeConfig && route.routeConfig.path) {
      // Store the detached route
      this.storedRoutes[route.routeConfig.path] = detachedTree;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // Check if the route should be reattached
    return !!route.routeConfig && !!this.storedRoutes[route.routeConfig.path];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || !this.storedRoutes[route.routeConfig.path]) {
      return null;
    }
    // Retrieve the stored route
    return this.storedRoutes[route.routeConfig.path];
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // Reuse the route if the future route is the same as the current
    return future.routeConfig === curr.routeConfig;
  }
}
