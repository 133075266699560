import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from '../../../services/firebase.service';
import { NotificationService } from 'src/app/core/services/notification/notification.service';

@Component({
  selector: 'app-new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.scss']
})
export class NewDocumentComponent implements OnInit {

  documentId:any='';
  collectionName:string='';
  btnLoader:boolean=false;
  btnDisabled:boolean=false;

  constructor(public dialogRef: MatDialogRef<NewDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialog,
  private fbService :FirebaseService,
  private notificationService:NotificationService) { }

  ngOnInit(): void {
    console.log("this.data is" , this.data);
    this.collectionName = this.data.collectionName;
  }
  close(){
    this.dialog.closeAll();
  }
  genAutoString(){
    this.documentId = this.fbService.generateRandomString(20);
  }

  onAddDocumentClick(): void {
    if(this.collectionName && this.documentId){
      this.btnLoader = true;
      this.btnDisabled = true;
      const newData = {};
  
      this.fbService.addDocument(this.collectionName, this.documentId,  newData)
        .then((documentRef) => {
          this.btnLoader = false;
          this.btnDisabled = false;
          this.dialogRef.close({status:true, documentId:this.documentId});
        })
        .catch((error) => {
          this.btnLoader = false;
          this.btnDisabled = false;
          this.notificationService.showError(
            'Error adding document:'
          );
          console.error('Error adding document:', error);
        });
    }
    }
}
