import { Injectable } from '@angular/core';

import { Store } from '../store';
import { NotificationItem } from '../../../layout/shared/models/notification.model';

export class UserState {
  isAdmin: boolean = false;
  isAuthenticated: boolean = false;
  claims: any = null;
  email: string = null;
  fileFeedbacks: any[] = [];
  fileFeedbackById: any = null;
  isTourComplete: boolean = true;
  jwt: string = null;
  payrollFileUploads: any[] = [];
  payrollFileUploadDates: string[] = [];
  plans: string[] = [];
  notifications: NotificationItem[] = [];
  user: any = null;
  userID: string = null;
  webToggles: any = null;
}

@Injectable({
  providedIn: 'root',
})
export class UserStore extends Store {
  constructor() {
    super(new UserState());
  }
}
