import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  resetLocalStorage(): void {
    localStorage.clear();
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  checkItem(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  updateItem(key: string, value: any): void {
    if (this.checkItem(key)) {
      this.setItem(key, value);
    }
  }

  countItems(): number {
    return localStorage?.length ? localStorage.length : 0;
  }
}
