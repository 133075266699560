<div class="collections-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="col-box-1 d-flex align-items-center">
        <button class="btn" (click)="goToHome()">
          <i class="fa fa-home big-i"></i>
        </button>
        <span class="items d-flex align-items-center" *ngIf="openedColName">
          <i class="fa fa-angle-right mx-2"></i> {{ openedColName }}</span
        >
        <span class="items d-flex align-items-center" *ngIf="openedDocId">
          <i class="fa fa-angle-right mx-2"></i> {{ openedDocId }}</span
        >
      </div>
    </div>
  </div>
  <div class="row row-boxes gx-0">
    <div class="col-md-3 border-right">
      <div class="box-header d-flex align-items-center">
        <div><i class="fas fa-angle-double-up"></i> <span>(default)</span></div>
      </div>
      <div class="box-body position-relative">
        <button class="btn start-btn" (click)="newCollection()">
          + Start collection
        </button>
        <div class="collection-list-wrapper">
          <app-f-loader *ngIf="colLoader"></app-f-loader>
          <ul class="list-ul">
            <ng-container
              *ngFor="let collection of allCollectionsList; let i = index">
              <!-- <li class="d-flex align-items-center justify-content-between" [class.active]="collection.isOpened" (click)="openCollection(collection.name, i)" *ngIf="collection.name != 'users'"> -->
              <li
                class="d-flex align-items-center justify-content-between"
                [class.active]="collection.isOpened"
                (click)="openCollection(collection.name, i)">
                <span class="d-flex align-items-center">
                  <!-- <span class="position-relative" *ngIf="collection.isOpened">
                                        <button (click)="deleteCollectionBtn2 = !deleteCollectionBtn2" class="btn del-i-btn-2"><i class="fa fa-ellipsis-v"></i></button>
                                        <button class="btn del-col-btn-2" *ngIf="deleteCollectionBtn2" (click)="deleteCollection()">Delete&nbsp;collection</button>
                                    </span> -->
                  {{ collection.name }}
                </span>
                <i *ngIf="collection.isOpened" class="fa fa-angle-right"></i>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="col-md-3 border-right"
      *ngIf="openedColName && openedColName != ''">
      <div class="box-header d-flex align-items-center justify-content-between">
        <div>
          <i class="fa fa-image"></i> <span>{{ openedColName }}</span>
        </div>
        <div class="d-flex align-items-center">
          <button class="btn btn-sm"><i class="fa fa-bars"></i></button>
          <span class="position-relative">
            <button
              class="btn btn-sm"
              (click)="deleteCollectionBtn = !deleteCollectionBtn">
              <i class="fa fa-ellipsis-v"></i>
            </button>
            <button
              class="btn del-col-btn"
              *ngIf="deleteCollectionBtn"
              (click)="deleteCollection()">
              Delete&nbsp;collection
            </button>
          </span>
        </div>
      </div>
      <div class="box-body position-relative">
        <button class="btn start-btn" (click)="newDocument()">
          + Add document
        </button>
        <div class="collection-list-wrapper">
          <app-f-loader *ngIf="docLoader"></app-f-loader>
          <ul class="list-ul">
            <li
              class="d-flex align-items-center justify-content-between"
              *ngFor="let doc of allDocumentsList; let i = index"
              [class.active]="doc.isOpened"
              (click)="openDocument(i, doc.id)">
              <span>{{ doc.id ? doc.id : '' }}</span>
              <i *ngIf="doc.isOpened" class="fa fa-angle-right"></i>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-6" *ngIf="openedDocId && openedDocId != ''">
      <div class="box-header d-flex align-items-center justify-content-between">
        <div>
          <i class="fa fa-building"></i> <span>{{ openedDocId }}</span>
        </div>
        <div class="position-relative">
          <button
            class="btn btn-sm"
            (click)="deleteDocumentBtn = !deleteDocumentBtn">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <button
            class="btn del-col-btn"
            *ngIf="deleteDocumentBtn"
            (click)="deleteDocument()">
            Delete&nbsp;document
          </button>
        </div>
      </div>
      <div class="box-body position-relative">
        <button class="btn start-btn" (click)="isNewField = !isNewField">
          + Add field
        </button>
        <div class="new-field-wrapper" *ngIf="isNewField">
          <app-f-new-field
            (newFieldEvent)="newFieldEventFun($event)"
            (isFieldAdded)="addNewField($event)"
            [collectionName]="openedColName"
            [documentId]="openedDocId">
          </app-f-new-field>
        </div>
        <div class="opened-document-wrapper">
          <div *ngIf="sinCompleteDoc">
            <ul class="list-ul">
              <li
                class="d-flex align-items-center"
                *ngFor="let property of sinCompleteDocProps">
                <strong>{{ property }}:</strong>
                <ng-container
                  *ngIf="typeOfValue(sinCompleteDoc[property]) != 'object'">
                  <input
                    type="text"
                    class="form-control"
                    [class.readonly-input]="!editableProperties[property]"
                    [(ngModel)]="sinCompleteDoc[property]"
                    [readonly]="!editableProperties[property]" />
                </ng-container>
                <ng-container
                  *ngIf="typeOfValue(sinCompleteDoc[property]) == 'object'">
                  <span>{{ sinCompleteDoc[property] | json }}</span>
                </ng-container>
                <button
                  class="btn edit-doc-btn"
                  *ngIf="property != 'id' && !editableProperties[property]"
                  (click)="startEditing(property)">
                  <i class="fa fa-edit text-primary"></i>
                </button>
                <button
                  class="btn edit-doc-btn"
                  *ngIf="property != 'id' && editableProperties[property]"
                  (click)="updateField(property)">
                  <i class="fa fa-check-circle text-success"></i>
                </button>
                <button
                  class="btn edit-doc-btn"
                  *ngIf="property != 'id'"
                  (click)="deleteField(property, sinCompleteDoc)">
                  <i class="fa fa-trash text-danger"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
