import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { FlexLayoutModule } from '@angular/flex-layout';

import { NgbSortableHeaderDirective } from './sortable.directive';
import { AdvancedTableComponent } from './advanced-table.component';
import { EditableDirective } from './editable.directive';

@NgModule({
  declarations: [
    AdvancedTableComponent,
    NgbSortableHeaderDirective,
    EditableDirective,
  ],
  imports: [CommonModule, NgbModule, FormsModule, ],
  exports: [AdvancedTableComponent],
})
export class AdvancedTableModule {}
