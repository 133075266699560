import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap, combineLatest, filter, take } from 'rxjs';

import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { EventService } from '../../../core/services/event/event.service';
import {
  LEFT_SIDEBAR_TYPE_CONDENSED,
  LEFT_SIDEBAR_TYPE_DEFAULT,
} from '../config/layout.model';
import { BrandItem } from '../models/brands.model';
import { CreateNewMenuOption } from '../models/create-new.model';
import { Language } from '../models/language.model';
import { NotificationItem } from '../models/notification.model';
import { ProfileOptionItem } from '../models/profileoption.model';
import { SearchResultItem, SearchUserItem } from '../models/search.model';
import { NotificationService } from '../../../core/services/notification/notification.service';
import { AdminService } from '../../../core/services/admin/admin.service';
import { UserService } from '../../../core/services/user/user.service';
import { IUser } from '../../../core/interfaces/user.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private event: EventService,
    private notifications: NotificationService,
    private user: UserService,
    private admin: AdminService
  ) {}

  @Input() layoutType: string = '';
  @Input() leftSidebarTheme: string = 'light';

  isAdmin$: Observable<boolean> = this.admin.isAdmin$;
  isAuthenticated$: Observable<boolean> = this.auth.isAuthenticated$;
  user$: Observable<any> = this.user.user$;
  userID$: Observable<string> = this.user.userID$;

  createMenuOptions: CreateNewMenuOption[] = [];
  notificationList: NotificationItem[] = [];
  brands: BrandItem[] = [];
  profileOptions: ProfileOptionItem[] = [];
  selectedLanguage?: Language;
  searchResults: SearchResultItem[] = [];
  searchUsers: SearchUserItem[] = [];

  isAdmin: boolean = false;
  topnavCollapsed: boolean = false;

  @Output() mobileMenuButtonClicked = new EventEmitter();
  @Output() settingsButtonClicked = new EventEmitter<boolean>();

  ngOnInit(): void {
    combineLatest([
      this.isAdmin$,
      this.isAuthenticated$,
      this.user$,
      this.userID$,
    ])
      .pipe(
        filter(
          ([isAdmin, isAuthenticated, user, userID]: [
            boolean,
            boolean,
            IUser,
            string
          ]) => isAuthenticated && !!user && !!user?.email && !!userID
        ),
        tap(
          ([isAdmin, isAuthenticated, user, userID]: [
            boolean,
            boolean,
            IUser,
            string
          ]) => {
            this.isAdmin = isAdmin;
            this.profileOptions = [];

            if (isAuthenticated) {
              this.fetchNotifications(userID);
              this.profileDropdownOptions();
              this.addDOMListeners();
              this.profileOptions.push({
                label: 'Account Settings',
                icon: 'fe-settings',
                redirectTo: '/auth/account',
              });
            }

            if (isAdmin) {
              this.profileOptions.push({
                label: 'Admin Dashboard',
                icon: 'fe-airplay',
                redirectTo: '/admin/dashboard',
              });
            }
          }
        )
      )
      .subscribe();
  }

  addDOMListeners(): void {
    document.addEventListener('fullscreenchange', this.exitHandler);
    document.addEventListener('webkitfullscreenchange', this.exitHandler);
    document.addEventListener('mozfullscreenchange', this.exitHandler);
  }

  /**
   * Fetches notifications
   */
  async fetchNotifications(id: string): Promise<NotificationItem[]> {
    return await this.notifications
      .getFirestoreUserNotifications(id)
      .then((data: any) => (this.notificationList = data));
  }

  markItemAsRead(item: NotificationItem): void {
    this.notificationList = this.notificationList.filter(
      (n) => n.text !== item.text
    );

    this.notifications.setFirestoreNotificationAsRead(item);
  }

  clearNotifications(): void {
    this.notificationList = [];
    this.notifications.clearFirestoreNotifications();
  }

  profileDropdownOptions(): void {
    this.profileOptions.push(
      {
        label: 'Lock Screen',
        icon: 'fe-lock',
        redirectTo: '/auth/lock-screen',
      },
      {
        label: 'Logout',
        icon: 'fe-log-out',
        redirectTo: '/auth/logout',
      }
      // {
      //   label: 'Settings',
      //   icon: 'fe-settings',
      //   redirectTo: '/auth/account-settings',
      // },
    );
  }

  /**
   * changes left sidebar width
   */
  changeSidebarWidth(): void {
    if (
      document.body.hasAttribute('data-leftbar-size') &&
      document.body.getAttribute('data-leftbar-size') === 'condensed'
    ) {
      this.event.broadcast('changeLeftSidebarType', LEFT_SIDEBAR_TYPE_DEFAULT);
    } else {
      this.event.broadcast(
        'changeLeftSidebarType',
        LEFT_SIDEBAR_TYPE_CONDENSED
      );
    }
  }

  /**
   * exit handler for full screen mode
   */
  exitHandler(): void {
    let document: any = window.document;

    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      document.body.classList.remove('fullscreen-enable');
    }
  }

  /**
   * toggles full screen mode
   */
  toggleFullScreen(): void {
    let document: any = window.document;

    document.body.classList.toggle('fullscreen-enable');

    let elem = document.querySelector('.maximize-icon');

    if (
      elem.hasAttribute('data-toggle') &&
      document.body.getAttribute('data-toggle') === 'fullscreen'
    ) {
      document.body.removeAttribute('data-toggle');
    } else {
      elem.setAttribute('data-toggle', 'fullscreen');
    }

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    this.topnavCollapsed = !this.topnavCollapsed;
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }
}
