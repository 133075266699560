import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Observable, from, map, of, switchMap } from 'rxjs';
import { getFirestore, doc, updateDoc, deleteField } from 'firebase/firestore';
import { initializeApp, FirebaseApp, getApps, deleteApp } from 'firebase/app';
import { getApp } from 'firebase/app';
import { Firestore, collection, getDocs, addDoc, deleteDoc } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';
import { environment } from '../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) {}

  plans$ = this.firestore.collection('plans').valueChanges();
  
  generateRandomString(length: number): string {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  private getCollectionsListUrl =
    'https://us-central1-retirement-plans-inc-test.cloudfunctions.net/listCollections';

  private deleteUserFunctionUrl =
    'https://us-central1-retirement-plans-inc-test.cloudfunctions.net/deleteUser';

  // TODO: junaid - fix
  private copyGlobalHeadersToProductionUrl =
    'https://us-central1-retirement-plans-inc.cloudfunctions.net/copyGlobalHeaders';

  private productionFirebaseApp: FirebaseApp | null = null;
  private productionFirestore: Firestore | null = null;

  getListOfCollections(): Observable<any> {
    return this.http.get<any>(this.getCollectionsListUrl);
  }

  // createCollection(collectionName: string, firstName: string): void {
  //   this.firestore.collection(collectionName).add({
  //     firstName: firstName,
  //   });
  // }

  createCollection(collectionName: string, documentId: string): void {
    this.firestore.collection(collectionName).doc(documentId).set({});
  }

  deleteCollection(collectionName: string): Observable<void> {
    const batch = this.firestore.firestore.batch();
    return this.firestore
      .collection(collectionName)
      .get()
      .pipe(
        // Use RxJS to merge multiple asynchronous operations into a single observable
        switchMap((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // Delete each document in the collection
            batch.delete(doc.ref);
          });
          return batch.commit(); // Commit the batch operation
        })
      );
  }

  getAllDocuments(collectionName: string) {
    const vehicles = this.firestore.collection(collectionName);

    return vehicles.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((action) => {
          const data: any = action.payload.doc.data();
          const id = action.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
  addFieldIsUsePlanHeaders(
    collectionName: string,
    documentId: string,
    fieldName: string,
    fieldValue: any
  ): Promise<void> {
    const dataToUpdate = { [fieldName]: fieldValue };
    return this.firestore
      .collection(collectionName)
      .doc(documentId)
      .update(dataToUpdate);
  }

  getCustomPlanParserOptions(collectionName: string, planId: string) {
    return this.firestore.collection(collectionName).doc(planId).get().pipe(
      map(snapshot => {
        if (snapshot.exists) {
          return snapshot.data();
        } else {
          return []; 
        }
      })
    );
  }

  addDocument(
    collectionName: string,
    documentId: string,
    data: any
  ): Promise<void> {
    const documentRef = this.firestore
      .collection(collectionName)
      .doc(documentId);
    return documentRef.set(data);
  }

  getDocumentById(collectionName: string, documentId: string) {
    const documentRef = this.firestore
      .collection(collectionName)
      .doc(documentId);

    return documentRef.snapshotChanges().pipe(
      map((action) => {
        const data: any = action.payload.data();
        const id = action.payload.id;
        return { id, ...data };
      })
    );
  }

  deleteDocument(collectionName: string, documentId: string): Promise<void> {
    return this.firestore.collection(collectionName).doc(documentId).delete();
  }

  addFieldToDocument(
    collectionName: string,
    documentId: string,
    fieldName: string,
    fieldValue: any
  ): Promise<void> {
    const dataToUpdate = { [fieldName]: fieldValue };
    return this.firestore
      .collection(collectionName)
      .doc(documentId)
      .update(dataToUpdate);
  }

  updateDocument(
    collectionName: string,
    documentId: string,
    dataToUpdate: any
  ): Promise<void> {
    return this.firestore
      .collection(collectionName)
      .doc(documentId)
      .update(dataToUpdate);
  }

  deleteFieldFromDocument(
    collectionName: string,
    documentId: string,
    fieldName: string
  ): Promise<void> {
    const firestore = getFirestore();
    const docRef = doc(firestore, collectionName, documentId);

    return updateDoc(docRef, {
      [fieldName]: deleteField(),
    });
  }

  getAllUsers(): Observable<any[]> {
    return this.firestore.collection('users').valueChanges();
  }

  async registerUser(email: string, password: string): Promise<any> {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        this.saveUserToFirestore(userCredential.user);
        return userCredential;
      })
      .catch((error) => {
        console.error('Error registering user:', error);
        throw error;
      });
  }

  saveUserToFirestore(user: any): void {
    this.firestore.collection('users').doc(user.uid).set({
      uid: user.uid,
      email: user.email,
    });
  }

  deleteUser(uid: string): Observable<any> {
    const url = `${this.deleteUserFunctionUrl}?uid=${uid}`;
    return this.http.delete(url);
  }

  addUserToPlan(planId: string, userEmail: string): Observable<any> {
    return this.getAllUsers().pipe(
      switchMap((users: any[]) => {
        const user = users.find((u) => u.email === userEmail);
        return of(user);
      }),
      switchMap((user: any) => {
        return this.firestore
          .collection('users')
          .snapshotChanges()
          .pipe(
            map((changes) =>
              changes.map((c) => ({
                id: c.payload.doc.id,
                ...(c.payload.doc.data() as any),
              }))
            ),
            map(
              (users) =>
                users.find((user) => user.email === userEmail)?.id || null
            ),
            switchMap((userId: string) => {
              return of(this.firestore.collection('users').doc(userId));
            }),
            switchMap((userRef: AngularFirestoreDocument<any>) => {
              return userRef.get().pipe(
                switchMap((doc) => {
                  if (doc.exists) {
                    const userData: any = doc.data();
                    const userPlans: any = userData?.plans || [];
                    if (!userPlans.includes(planId)) {
                      userPlans.push(planId);
                      return from(userRef.update({ plans: userPlans }));
                    } else {
                      return of(null);
                    }
                  } else {
                    throw new Error(
                      `User document does not exist: ${user?.uid}`
                    );
                  }
                })
              );
            })
          );
      })
    );
  }

  removeUserFromPlan(planId: string, userEmail: string): Observable<any> {
    return this.getAllUsers().pipe(
      switchMap((users: any[]) => {
        const user = users.find((u) => u.email === userEmail);
        return of(user);
      }),
      switchMap((user: any) => {
        return this.firestore
          .collection('users')
          .snapshotChanges()
          .pipe(
            map((changes) =>
              changes.map((c) => ({
                id: c.payload.doc.id,
                ...(c.payload.doc.data() as any),
              }))
            ),
            map(
              (users) =>
                users.find((user) => user.email === userEmail)?.id || null
            ),
            switchMap((userId: string) => {
              return of(this.firestore.collection('users').doc(userId));
            }),
            switchMap((userRef: AngularFirestoreDocument<any>) => {
              return userRef.get().pipe(
                switchMap((doc) => {
                  if (doc.exists) {
                    const userData: any = doc.data();
                    const userPlans: any = userData?.plans || [];
                    if (userPlans.includes(planId)) {
                      userPlans.splice(userPlans.indexOf(planId), 1);
                      return from(userRef.update({ plans: userPlans }));
                    } else {
                      return of(null);
                    }
                  } else {
                    throw new Error(
                      `User document does not exist: ${user?.uid}`
                    );
                  }
                })
              );
            })
          );
      })
    );
  }

  // TODO: Add a method to copy global headers from test to production
  // TODO: junaid - fix
  copyGlobalHeadersToProduction(globalParserMappings: any, allowInitialize: boolean): Observable<any> {
    this.initializeProductionFirebase(allowInitialize);
    
    const developmentCollection = 'globalParserMappings';
    const productionCollection = 'globalParserMappings';

    const developmentCollectionRef = collection(this.firestore.firestore, developmentCollection);
    const productionCollectionRef = collection(this.productionFirestore, productionCollection);

    return new Observable(observer => {
      const promises = [];
      globalParserMappings.forEach(async (documentData) => {
        // Check if the document already exists in production
        const q = query(productionCollectionRef, where('id', '==', documentData.id)); // Assuming each document has an 'id' field
        const querySnapshot = await getDocs(q);
        const matchingDocuments = querySnapshot.docs;

        if (matchingDocuments.length > 0) {
          // Document exists in production, update it if it also exists in development
          const developmentQuerySnapshot = await getDocs(query(developmentCollectionRef, where('id', '==', documentData.id)));
          if (developmentQuerySnapshot.size > 0) {
            const docRef = matchingDocuments[0].ref; 
            promises.push(updateDoc(docRef, documentData));
          } else {
            // Document doesn't exist in development, delete it from production
            promises.push(deleteDoc(matchingDocuments[0].ref));
          }
        } else {
          // Document exist in development, add it in production
            promises.push(addDoc(productionCollectionRef, documentData));
        }
      });

      Promise.all(promises)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch(error => {
          observer.error(error);
        });
    });
  }


  private initializeProductionFirebase(allowInitialize): void {
    const firebaseConfigForProduction = environment?.firebaseProductionConfiguration;
    try {
      if (allowInitialize) {
        this.productionFirebaseApp = initializeApp(firebaseConfigForProduction, 'rpiForProductionEnviornment');
      }
    } catch (error) {
      if (error.code !== 'app/duplicate-app') {
        throw error;
      }
      this.productionFirebaseApp = getApp('rpiForProductionEnviornment');
    }
    this.productionFirestore = getFirestore(this.productionFirebaseApp);
  }
}
