import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { AdminService } from '../services/admin/admin.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private admin: AdminService, private router: Router) {}

  /**
   * Checks if the user has admin privileges. If not, redirects them to the employee dashboard.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.admin.isAdmin$.pipe(
      take(1),
      map((isAdmin: boolean) => {
        if (isAdmin) {
          return true;
        } else {
          this.router.navigate(['/auth/dashboard']);
          return false;
        }
      }),
      catchError((error) => {
        console.error('Error checking admin privileges:', error);
        this.router.navigate(['/']);
        return of(false);
      })
    );
  }
}
