import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from '../../../services/firebase.service';

@Component({
  selector: 'app-new-collection',
  templateUrl: './new-collection.component.html',
  styleUrls: ['./new-collection.component.scss']
})
export class NewCollectionComponent implements OnInit {

  collectionId:any='';
  documentId:any='';
  secondStep:boolean=false;
  btnLoader:boolean=false;
  btnDisabled:boolean=false;

  constructor(public dialogRef: MatDialogRef<NewCollectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  private dialog: MatDialog,
  private fbService:FirebaseService) { }

  ngOnInit(): void {
  }
  close(){
    this.dialog.closeAll();
  }

  nextStep(){
    this.secondStep = true;
  }

  genAutoString(){
    this.documentId = this.fbService.generateRandomString(20);
  }

  createNewCollection(){
    if(this.collectionId && this.documentId){
      this.btnLoader = true;
      this.btnDisabled = true;
      const collectionName = this.collectionId;
      const firstName = this.documentId; 
      this.fbService.createCollection(collectionName, firstName);
      this.dialogRef.close({status:true});
    }
  }

}
