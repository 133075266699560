<div class="col-12">
    <div class="auth-wrapper">
        <div class="row gx-0">
            <div class="col-8">
                <div class="form-group position-relative search-input">
                    <input type="text" class="form-control" placeholder="Search by email address" [(ngModel)]="searchEmailValue" (keyup)="filterArrayByEmail(searchEmailValue)">
                    <i class="fa fa-search" aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-4">
                <div class="d-flex align-items-center justify-content-lg-end">
                    <button class="btn add-new-btn" (click)="addNewUser()">Add user</button>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="table-wrapper">
                    <app-f-loader *ngIf="isLoading"></app-f-loader>
                    <app-advanced-table
                    class="text-center"
                    (sort)="sort.onTableSort($event, allUsersList)"
                    [tableData]="allUsersList"
                    [isSortable]="true"
                    [isSearchable]="false"
                    [pagination]="true"
                    [hasRowSelection]="isEditable"
                    [pageSizeOptions]="[10, 30, 50]"
                    [columns]="censusDataColumns"
                    (deleteAction)= deleteFUser($event)>
                  </app-advanced-table>
                </div>
                <!-- <div class="tab">
                    <app-f-loader *ngIf="isLoading"></app-f-loader>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <th>Identifier</th>
                                <th>Providers</th>
                                <th>Created</th>
                                <th>Signed&nbsp;In</th>
                                <th>User&nbsp;UID</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of usersData">
                                    <td class="w-25">{{ data.email ? data.email : '' }}</td>
                                    <td><i class="fa fa-envelope"></i></td>
                                    <td>{{ data.created ? (data.created | date: 'MMM d, y') : '' }}</td>
                                    <td>{{ data.updated ? (data.updated | date: 'MMM d, y') : '' }}</td>
                                    <td>{{ data.uid ? data.uid : '' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex flex-wrap justify-content-between p-2">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"  [maxSize]="5" (pageChange)="refreshData()"></ngb-pagination>
                  
                        <div class="d-flex flex-wrap align-items-center">
                          <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshData()">
                            <option [ngValue]="3">3</option>
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="30">30</option>
                          </select>
                          <p class="m-0 pl-2">Showing {{ usersData.length }} Records out of {{ collectionSize }}</p>
                        </div>
                      </div>
                </div> -->
            </div>
        </div>
    </div>
</div>