import { Component, OnInit } from '@angular/core';
import { ParsedToken } from 'firebase/auth';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';
import { NavigationService } from './core/services/navigation/navigation.service';
import { UserService } from './core/services/user/user.service';
import { NotificationService } from './core/services/notification/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  admin$: Observable<boolean> = this.user.admin$;
  authenticated$: Observable<boolean> = this.user.authenticated$;
  claims$: Observable<ParsedToken> = this.user.claims$;
  jwt$: Observable<string> = this.user.jwt$;
  navigation$: Observable<any> = this.nav.navigation$;
  navigationCurrentRoute$: Observable<string> = this.nav.currentRoute$;
  notifications$: Observable<any[]> = this.notifications.notifications$;
  plans$: Observable<any> = this.user.plans$;
  tour$: Observable<boolean> = this.user.tour$;
  user$: Observable<any> = this.user.user$;

  loading: boolean = true;
  title: string = 'Retirement Plans, Inc';

  constructor(
    private nav: NavigationService,
    private notifications: NotificationService,
    private user: UserService
  ) {}

  ngOnInit(): void {
    this.initializeFirebase();
    this.printConsoleWarning();
    this.loading = false;
  }

  initializeFirebase(): void {
    this.plans$.subscribe();
    this.user$.subscribe();
    this.jwt$.subscribe();
    this.authenticated$.subscribe();
    this.claims$.subscribe();
    this.admin$.subscribe();
    this.tour$.subscribe();
    this.notifications$.subscribe();
  }

  isAdminRoute(route: string): boolean {
    const includes: boolean = route?.includes('admin');
    return includes ? true : false;
  }

  isAuthenticatedRoute(route: string): boolean {
    const includes: boolean = route?.includes('auth');
    return includes ? true : false;
  }

  isNonAuthenticatedRoute(route: string): boolean {
    const doesNotInclude: boolean =
      !this.isAdminRoute(route) && !this.isAuthenticatedRoute(route);
    return doesNotInclude ? true : false;
  }

  onActivate(event?: any): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  printConsoleWarning(): void {
    console.clear();
    console.log(
      '%cSTOP',
      'color: red; font-size: 40px; font-weight: bold; background-color: yellow;'
    );

    console.log(
      '%cUnauthorized access detected',
      'color: red; font-size: 30px; font-weight: bold; background-color: #ffcccc;'
    );

    console.log(
      '%cAccessing, tampering with, or using the developer tools or any components of this website or its server without explicit authorization is strictly forbidden. Any malicious activity or breach will be tracked, recorded, and reported. Violators will be prosecuted to the fullest extent of the law. This website employs active monitoring mechanisms. Close the developer tools immediately and cease any unauthorized actions.',
      'color: red; font-size: 20px; font-weight: bold;'
    );
  }
}
