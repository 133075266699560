import { AfterViewInit, Component, OnInit } from '@angular/core';
import { EventService } from '../core/services/event/event.service';
import {
  LAYOUT_DETACHED,
  LAYOUT_HORIZONTAL,
  LAYOUT_TWO_COLUMN_MENU,
  LAYOUT_VERTICAL,
  LAYOUT_WIDTH_BOXED,
  LEFT_SIDEBAR_TYPE_CONDENSED,
  LEFT_SIDEBAR_TYPE_DEFAULT,
} from './shared/config/layout.model';
import { getLayoutConfig } from './shared/helper/utils';
import { LayoutConfig } from './shared/models/layout-config.model';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-layout-container',
  template: `
    <!-- vertical layout -->
    <app-vertical-layout
      [layoutWidth]="layoutWidth"
      [layoutColor]="layoutColor"
      *ngIf="isVerticalLayoutRequested()">
    </app-vertical-layout>

    <!-- horizontal layout -->
    <app-horizontal-layout
      *ngIf="isHorizontalLayoutRequested()"
      [layoutColor]="layoutColor"
      [layoutWidth]="layoutWidth">
    </app-horizontal-layout>

    <!-- detached layout -->
    <app-detached-layout
      [layoutWidth]="layoutWidth"
      [layoutColor]="layoutColor"
      *ngIf="isDetachedLayoutRequested()">
    </app-detached-layout>

    <!-- two-column-menu layout -->
    <app-two-column-menu-layout
      [layoutWidth]="layoutWidth"
      [layoutColor]="layoutColor"
      *ngIf="isTwoColumnMenuLayoutRequested()">
    </app-two-column-menu-layout>
  `,
})
export class LayoutContainerComponent implements OnInit, AfterViewInit {
  layoutType!: string;
  layoutColor!: string;
  layoutConfig!: LayoutConfig;
  layoutWidth!: string;
  configuredDemo: string = 'default';

  constructor(private eventService: EventService) {}

  ngOnInit(): void {
    this.configuredDemo = 'creative';

    this.layoutType =
      this.configuredDemo === 'creative'
        ? LAYOUT_HORIZONTAL
        : this.configuredDemo === 'modern'
        ? LAYOUT_DETACHED
        : this.configuredDemo === 'saas'
        ? LAYOUT_TWO_COLUMN_MENU
        : LAYOUT_VERTICAL;
    this.setLayoutConfig();

    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
      this.setLayoutConfig();
    });

    this.eventService.subscribe('changeLayoutColor', (color) => {
      setTimeout(() => {
        this.layoutColor = color;
      }, 20);
    });

    this.eventService.subscribe('changeLayoutWidth', (width) => {
      setTimeout(() => {
        this.layoutWidth = width;
        if (this.layoutWidth === LAYOUT_WIDTH_BOXED) {
          this.eventService.broadcast(
            'changeLeftSidebarType',
            LEFT_SIDEBAR_TYPE_CONDENSED
          );
        } else {
          this.eventService.broadcast(
            'changeLeftSidebarType',
            LEFT_SIDEBAR_TYPE_DEFAULT
          );
        }
      }, 20);
    });

    this.updateDimensions();
  }

  ngAfterViewInit(): void {
    document.body.classList.remove(
      'authentication-bg',
      'authentication-bg-pattern',
      'auth-fluid-pages',
      'pb-0'
    );
  }

  /**
   * set layout config
   */
  setLayoutConfig(): void {
    this.layoutConfig = getLayoutConfig(this.layoutType);
    this.layoutWidth = this.layoutConfig.layoutWidth;
    this.layoutColor = this.layoutConfig.layoutColor;
  }

  /**
   * changes left sidebar type based on screen dimensions
   */
  updateDimensions(): void {
    if (this.layoutType !== LAYOUT_TWO_COLUMN_MENU) {
      if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
        this.eventService.broadcast(
          'changeLeftSidebarType',
          LEFT_SIDEBAR_TYPE_CONDENSED
        );
      } else if (window.innerWidth > 1028) {
        this.eventService.broadcast(
          'changeLeftSidebarType',
          LEFT_SIDEBAR_TYPE_DEFAULT
        );
      }
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }

  /**
   * Check if the detached layout is requested
   */
  isDetachedLayoutRequested() {
    return this.layoutType === LAYOUT_DETACHED;
  }

  /**
   * Check if two column layout is requested
   */
  isTwoColumnMenuLayoutRequested() {
    return this.layoutType === LAYOUT_TWO_COLUMN_MENU;
  }
}
