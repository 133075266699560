<div class="new-filed-wrapper">
  <div class="d-flex align-items-center">
    <div class="form-group">
      <label id="field">Field</label>
      <input type="text" class="form-control" id="field" [(ngModel)]="parrentSelName">
    </div>
    <div class="mx-2 mt-3">
      =
    </div>
    <div class="row gx-0">
      <div class="col-6">
        <div class="form-group">
          <label id="type">Type</label>
          <select class="form-select m-w-150px" id="type" [(ngModel)]="parrentSelType" (change)="parrentTypeChange($event)">
            <option *ngFor="let type of fieldTypes" [value]="type.type">{{ type.type }}</option>
          </select>
        </div>
      </div>
      <div class="col-6" *ngIf="parrentSelType == 'string' || parrentSelType == 'number' || parrentSelType == 'boolean'">
        <!--parrent for string, number -->
        <div class="form-group" *ngIf="parrentSelType == 'string' || parrentSelType == 'number'">
          <label id="value">Value</label>
          <input [type]="parrentSelType == 'string' ? 'text' : 'number'" class="form-control" id="value" [(ngModel)]="parrentSelValue">
        </div>
        <!--parrent for boolean -->
        <div class="form-group" *ngIf="parrentSelType == 'boolean'">
          <label id="type">Type</label>
          <select class="form-select" id="type" [(ngModel)]="parrentSelValue">
            <option value="true">true</option>
            <option value="false">false</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <!-- type timestamp structure -->
  <ng-container *ngIf="parrentSelType == 'timestamp'">
    <div class="col-6 mt-3 ms-3">
      <div class="form-group">
        <label for="dateVal">Date</label>
        <input type="date" class="form-control" id="dateVal" [(ngModel)]="timestampDate">
      </div>
      <div class="form-group mt-3">
        <label for="timeVal">Time</label>
        <input type="time" class="form-control" id="timeVal" [(ngModel)]="timestampTime">
      </div>
    </div>
  </ng-container>
  <!-- type geopoint structure -->
  <ng-container *ngIf="parrentSelType == 'geopoint'">
    <div class="col-6 mt-3 ms-3">
      <div class="form-group">
        <label for="latitude">Latitude</label>
        <input type="number" class="form-control" id="latitude" value="0" [(ngModel)]="geoLatitube" max="90">
      </div>
      <div class="form-group mt-3">
        <label for="longitude">Longitude</label>
        <input type="number" class="form-control" id="longitude" value="0" [(ngModel)]="geoLongtitude" max="90">
      </div>
    </div>
  </ng-container>
  <!-- type reference structure -->
  <ng-container *ngIf="parrentSelType == 'reference'">
    <div class="col-10 mt-3 ms-3">
      <div class="form-group">
        <label for="reference">Reference</label>
        <input type="text" class="form-control" id="reference" placeholder="Ex: collection/document001/" [(ngModel)]="referenceValue">
      </div>
    </div>
  </ng-container>
  <!-- type array structure -->
  <ng-container *ngIf="parrentSelType == 'array'">
    <ng-container *ngFor="let item of childTypeArray; let i=index">
      <div class="d-flex align-items-center mt-3 ms-3">
        <div>
          <label>&nbsp;</label>
          <input type="text" value="{{ i }}" class="form-control w-40px bg-light" readonly>
        </div>
        <div class="d-flex align-items-center">
          <div class="form-group">
            <label id="type_{{ i }}">Type</label>
            <select class="form-select" id="type_{{ i }}" [(ngModel)]="item.type">
              <option *ngFor="let type of chsildfieldTypes" [value]="type.type">{{ type.type }}</option>
            </select>
          </div>
          <div class="form-group" *ngIf="item.type == 'string' || item.type == 'number'">
            <label id="value_{{ i }}">Value</label>
            <input [type]="item.type == 'string' ? 'text' : 'number'" class="form-control" id="value_{{ i }}" [(ngModel)]="item.value">
          </div>
          <div class="form-group" *ngIf="item.type == 'boolean'">
            <label id="type">Type</label>
            <select class="form-select" id="type" [(ngModel)]="item.value">
              <option value="true">true</option>
              <option value="false">false</option>
            </select>
          </div>
          <button class="btn mt-2" (click)="removeArrayItem(i)"><i class="fa fa-minus-circle"></i></button>
        </div>
      </div>
      <ng-container *ngIf="item.type == 'timestamp'">
        <div class="col-6 mt-3 ms-3">
          <div class="form-group">
            <label for="dateVal_{{ i }}">Date</label>
            <input type="date" class="form-control" id="dateVal_{{ i }}" [(ngModel)]="item.timestampDate">
          </div>
          <div class="form-group mt-3">
            <label for="timeVal">Time</label>
            <input type="time" class="form-control" id="timeVal" [(ngModel)]="item.timestampTime">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="item.type == 'geopoint'">
        <div class="col-6 mt-3 ms-3">
          <div class="form-group">
            <label for="latitude_{{ i }}">Latitude</label>
            <input type="number" class="form-control" id="latitude_{{ i }}" value="0" [(ngModel)]="item.geoLatitube">
          </div>
          <div class="form-group mt-3">
            <label for="longitude_{{ i }}">Longitude</label>
            <input type="number" class="form-control" id="longitude_{{ i }}" value="0" [(ngModel)]="item.geoLongtitude">
          </div>
        </div>
      </ng-container>
      <!-- type reference structure -->
      <ng-container *ngIf="item.type == 'reference'">
        <div class="col-10 mt-3 ms-3">
          <div class="form-group">
            <label for="reference_{{ i }}">Reference</label>
            <input type="text" class="form-control" id="reference_{{ i }}" placeholder="Ex: collection/document001/" [(ngModel)]="item.referenceValue">
          </div>
        </div>
      </ng-container>
    </ng-container>
    <button class="btn mt-3" (click)="addNewArrayItem()" [class.disabled]="!arrayValidation()"><i class="fa fa-plus-circle"></i> Add field</button>
  </ng-container>
  <div class="d-flex align-items-center justify-content-end mt-4">
    <button class="btn btn-cancel" (click)="cancelFieldFun()">Cancel</button>
    <button class="btn btn-next" (click)="addNewField()" [disabled]="!btnValidator() || btnDisabled">
      <div *ngIf="btnLoader" class="spinner-border spinner-border-sm me-1" role="status">
        <span class="sr-only">Loading...</span>
    </div>Add</button>
  </div>
</div>
