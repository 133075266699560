export const environment = {
  production: false,
  accessKeyS3: 'AKIASF32VRIAKFEFHTUV',
  secretKeyS3: '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',
  regionS3: 'us-east-1',
  payrollBucketName: 'rpi-payrolls-test',
  censusBucketName: 'rpi-census-test',
  processedPayrollsBucketName: 'rpi-payroll-processed-amounts-test',
  sftpUploadsBucketName: 'rpi-sftp-uploads',
  uploadConfirmationsBucketName: 'rpi-upload-confirmations-test',
  webTogglesBucketName: 'rpi-web-toggles-test',
  googleMapsKey: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4',
  githubToken: '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',
  apiUrl: 'https://api-test.rpi401k.com/v1',
  firebase: {
    apiKey: 'AIzaSyAh2sTE3Y2XUg2xHH_qPRNOJcoxiYjjHfk',
    authDomain: 'retirement-plans-inc-test.firebaseapp.com',
    databaseURL: 'https://retirement-plans-inc-test-default-rtdb.firebaseio.com',
    projectId: 'retirement-plans-inc-test',
    storageBucket: 'retirement-plans-inc-test.appspot.com',
    messagingSenderId: '793849482594',
    appId: '1:793849482594:web:e3cd94d87e9c9afbfaf03b',
    measurementId: 'G-SKW5EVLREK',
  },
  firebaseProductionConfiguration: {
    apiKey: "AIzaSyDoCxGJAlptud_WhE7-Jun_xPfLcHwt5LI",
    authDomain: "retirement-plans-inc.firebaseapp.com",
    databaseURL: "https://retirement-plans-inc-default-rtdb.firebaseio.com",
    projectId: "retirement-plans-inc",
    storageBucket: "retirement-plans-inc.appspot.com",
    messagingSenderId: "1033599016869",
    appId: "1:1033599016869:web:73ac6f7823e25fe57d124c",
    measurementId: "G-737VBCCKJW"
}
};
