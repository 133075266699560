import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';

import { UserStore } from '../user/user.store';
import { TokenService } from '../token/token.service';
import { SweetalertsService } from '../sweetalerts/sweetalerts.service';
import { PERSISTENCE_SESSION } from '../../constants/authentication';
import { UserCredential } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isAuthenticated$: Observable<boolean> = this.store.select('isAuthenticated');

  constructor(
    private afAuth: AngularFireAuth,
    private store: UserStore,
    private swal: SweetalertsService,
    private token: TokenService
  ) {
    this.afAuth.setPersistence(PERSISTENCE_SESSION);
  }

  isAuthenticated(): boolean {
    const isAccessTokenValid: boolean = this.token.isAccessTokenValid(
      this.token.getAccessToken()
    );
    return isAccessTokenValid && this.store.get('isAuthenticated');
  }

  async loginWithEmail(
    email: string,
    password: string
  ): Promise<UserCredential | null> {
    return await this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((cred) => cred)
      .catch((error) => {
        this.swal.triggerModal(error, false, 'Login Error', 'There was an error logging in. Please make sure the email and password are correct. Contact support if the problem persists.');
        return null;
      });
  }

  async loginWithGoogle(): Promise<UserCredential | null> {
    return await this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((cred) => cred)
      .catch((error) => {
        this.swal.triggerModal(error, false, 'Error logging in');
        return null;
      });
  }

  async registerWithEmail(email: string, password: string): Promise<void> {
    await this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(() =>
        this.swal.triggerModal(
          null,
          true,
          'Registration Successful - Please login to continue.'
        )
      )
      .catch((error) =>
        this.swal.triggerModal(error, false, 'Error registering user')
      );
  }

  async forgotPassword(email: string): Promise<void> {
    await this.afAuth
      .sendPasswordResetEmail(email)
      .then(() =>
        this.swal.triggerModal(
          null,
          true,
          'Password reset email sent, check your inbox.'
        )
      )
      .catch((error) =>
        this.swal.triggerModal(
          error,
          false,
          'Error sending password reset email'
        )
      );
  }

  set(key: string, value: any): void {
    this.store.set(key, value);
  }
}
