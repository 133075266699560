import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-f-sidebar',
  templateUrl: './f-sidebar.component.html',
  styleUrls: ['./f-sidebar.component.scss']
})
export class FSidebarComponent implements OnInit {

  isSidebarFull:boolean=true;
  isContentShow:boolean=true;
  @Output() isFSidebarFull:any = new EventEmitter();
  isFirstLoad:boolean=true;

  constructor(public sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
  }

  collapsSidebar(){
    this.isSidebarFull = !this.isSidebarFull;
    this.isFSidebarFull.emit(this.isSidebarFull);
    if(this.isContentShow){
      this.isContentShow = !this.isContentShow;
    }else{
      setTimeout(() => {
        this.isContentShow = !this.isContentShow;
      }, 200);
    }
  }

}
