<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <!-- <div class="col-md-6">
        {{ currentYear | date : 'yyyy'}} &copy; SaaS Developed by <a href="https://www.lindseywebsolutions.com/home">Lindsey Web Solutions</a>
        <br>
        {{ currentYear | date : 'yyyy'}} &copy; Theme by <a href="javascript:void(0);">Coderthemes</a> & <a href="https://www.lindseywebsolutions.com/home">Lindsey Web Solutions</a>
      </div> -->
    </div>
  </div>
</footer>
