import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NotificationService } from '../services/notification/notification.service';
import { UserAuthenticationService } from '../services/user/user-authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private userAuth: UserAuthenticationService,
    private notificationService: NotificationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          console.error('Client side error:', error.error.message);
          this.notificationService.showError(
            'An error occurred. Please try again later.'
          );
        } else {
          switch (error.status) {
            case 401:
              this.userAuth.logout();
              this.notificationService.showError(
                'Session expired. Please log in again.'
              );
              break;
            case 403:
              this.notificationService.showError(
                "You don't have permission to access this resource."
              );
              break;
            case 404:
              this.notificationService.showError('Resource not found.');
              break;
            case 500:
              this.notificationService.showError(
                'Internal server error. Please try again later.'
              );
              break;
            default:
              this.notificationService.showError(
                error.error.message || error.statusText
              );
          }
        }

        return throwError(error);
      })
    );
  }
}
