<div class="card">
  <div class="card-body">
    <h4 class="header-title mb-3">{{ cardTitle }}</h4>

    <div class="widget-chart text-center" dir="ltr">
      <!-- <apx-chart [series]="chartOptions.series!" [chart]="chartOptions.chart!"
                [plotOptions]="chartOptions.plotOptions!" [colors]="chartOptions.colors!"
                [stroke]="chartOptions.stroke!"></apx-chart> -->
      <h5 class="text-muted mt-1">Total sales made today</h5>
      <h2>{{ totalSales }}</h2>

      <div class="row mt-3">
        <div class="col-4">
          <p class="text-muted font-15 mb-1 text-truncate">Target</p>
          <h4>{{ target }}</h4>
        </div>
        <div class="col-4">
          <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
          <h4>{{ lastWeek }}</h4>
        </div>
        <div class="col-4">
          <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
          <h4>{{ lastMonth }}</h4>
        </div>
      </div>
      <!-- end row -->
    </div>
  </div>
</div>
<!-- end card -->
