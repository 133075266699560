import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TableHeaderFormatterService {
  constructor() {}

  convertToDollarString(amount: number|string): string {
    const amt = typeof amount === 'string' ? parseFloat(amount) : amount;
    if (isNaN(amt) || amt === 0) {
      return '-';
    }
    return `$${amt.toFixed(2)}`;
  }

  convertToTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}
