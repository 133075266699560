import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  IdTokenResult,
  ParsedToken,
  User,
  UserCredential,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import {
  Observable,
  catchError,
  combineLatest,
  filter,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { NotificationItem } from '../../../layout/shared/models/notification.model';
import { UserStore } from './user.store';
import { AdminService } from '../admin/admin.service';
import { StorageService } from '../storage/storage.service';
import { NavigationService } from '../navigation/navigation.service';
import { FirestoreService } from '../firebase/firestore.service';
import { TokenService } from '../token/token.service';
import { IUser } from '../../interfaces/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private isAuthenticatedStorage = 'isAuthenticated';
  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private admin: AdminService,
    private firestore: FirestoreService,
    private nav: NavigationService,
    private store: UserStore,
    private storage: StorageService,
    private token: TokenService
  ) {
    const isAuthenticatedFromStorage = localStorage.getItem(this.isAuthenticatedStorage);
    if (isAuthenticatedFromStorage) {
      this.store.set('isAuthenticated', isAuthenticatedFromStorage === 'true');
    }
  }

  plans$: Observable<any[]> = this.store.select('plans');
  users$: Observable<any[]> = this.store.select('users');

  user$: Observable<User> = this.afAuth.authState.pipe(
    filter((user: User) => !!user && !!user?.email),
    tap((user) => {
      this.store.set('email', user?.email);
      this.store.set('user', user);
      this.store.set('userID', user.uid);
      this.storage.setItem('user', user);
      this.storage.setItem('userID', user.uid);
    }),
    switchMap((user: User) => {
      const userID: string = user?.uid;
      if (userID) {
        return this.afs.doc<any>(`users/${userID}`).valueChanges();
      } else {
        return of(null);
      }
    })
  );

  userID$: Observable<string> = this.store.select('userID');

  claims$: Observable<ParsedToken> = this.afAuth.authState.pipe(
    filter((user: User) => !!user && !!user?.email),
    switchMap(async (user: User) => await user?.getIdTokenResult()),
    filter((token: IdTokenResult) => !!token && !!token?.claims),
    map((token: IdTokenResult) => token.claims),
    tap((claims: ParsedToken) => this.store.set('claims', claims)),
    map((claims: ParsedToken) => claims)
  );

  jwt$: Observable<string> = this.afAuth.authState.pipe(
    filter((user: User) => !!user && !!user?.email),
    switchMap(async (user: User) => await user?.getIdToken()),
    filter((jwt: string) => !!jwt && !!jwt?.length),
    tap((jwt: string) => this.store.set('jwt', jwt)),
    map((jwt: string) => jwt)
  );

  tour$: Observable<boolean> = this.user$.pipe(
    filter((user: User) => !!user && !!user?.email),
    take(1),
    switchMap(async () => await this.getTourComplete()),
    tap((isTourComplete: boolean) =>
      this.store.set('isTourComplete', isTourComplete)
    ),
    map((isTourComplete: boolean) => isTourComplete)
  );

  isTourComplete$: Observable<boolean> = this.store.select('isTourComplete');

  authenticated$: Observable<boolean> = combineLatest([
    this.user$,
    this.jwt$,
  ]).pipe(
    map(([user, jwt]: [User, string]) =>
      !!user?.email && !!jwt && this.token.isAccessTokenValid(jwt)
        ? true
        : false
    ),
    tap((isAuthenticated: boolean) => {
      this.store.set('isAuthenticated', isAuthenticated);
      localStorage.setItem(this.isAuthenticatedStorage, isAuthenticated.toString());
    }),
    map((isAuthenticated: boolean) => isAuthenticated)
  );

  admin$: Observable<boolean> = combineLatest([
    this.authenticated$,
    this.claims$,
  ]).pipe(
    filter(
      ([authenticated, claims]: [boolean, ParsedToken]) =>
        authenticated && !!claims
    ),
    map(
      ([authenticated, claims]: [boolean, ParsedToken]) =>
        (authenticated && claims?.admin) || false
    ),
    tap((isAdmin: boolean) => {
      this.nav.createNavigationRoutes(isAdmin, this.store.get('email'));
      this.admin.set('isAdmin', isAdmin);
    }),
    map((isAdmin: boolean) => isAdmin)
  );

  addUserToPlan(email: string, planId: string): Observable<any> {
    return this.getUserIDByEmail(email).pipe(
      filter((userID) => !!userID && !!userID.length),
      switchMap(async (userID: string) => {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
          `users/${userID}`
        );
        userRef.ref
          .get()
          .then((doc: firebase.firestore.DocumentSnapshot<any>) => {
            const userPlans: string[] = doc?.data()?.plans || [];
            userPlans.push(planId);
            userRef.set({ plans: userPlans }, { merge: true });
          });
      })
    );
  }

  async exists(credential: UserCredential): Promise<boolean> {
    const exists: boolean = (await this.getUser(credential)) !== null;
    return exists;
  }

  createUserWithoutCredential(email: string): Observable<any> {
    return from(
      this.afs
        .collection('users')
        .add({
          uid: null,
          email: null,
          notifications: [],
          plans: [],
          created: new Date().toLocaleString('en-US'),
          updated: new Date().toLocaleString('en-US'),
        })
        .then((docRef: firebase.firestore.DocumentReference<any>) => {
          docRef.update({ uid: docRef.id, email });
          return docRef;
        })
    );
  }

  async createUser(credential: UserCredential): Promise<any> {
    const jwt: string = await credential?.user?.getIdToken();
    const userID: string = credential?.user?.uid;

    if (userID) {
      const user: User | any = credential?.user;
      const name: string = user?.name ? user.name : user?.displayName;
      const email: string = user?.email;
      const plans: any[] = user?.plans ? user.plans : [];
      const welcomeMessage: string = !!name ? `Welcome ${name}` : 'Welcome';

      const userData: any = {
        // uid: userID,
        jwt,
        email,
        name,
        notifications: [
          {
            active: true,
            link: '/auth/dashboard',
            message: `${welcomeMessage}, to the RPI401k Payroll System`,
          },
        ],
        plans,
        tour: true,
        created: new Date().toLocaleString('en-US'),
        updated: new Date().toLocaleString('en-US'),
      };

      userData.notifications.push({
        active: true,
        link: '/auth/dashboard',
        message: `${welcomeMessage}! Check out the payroll portal tour before getting started.`,
      });

      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${userID}`
      );

      await userRef.set(userData, { merge: true });
      return user;
    } else {
      console.error('No user ID found while creating user.');
      return null;
    }
  }

  deleteEmptyUsers(): Observable<any> {
    return this.afs
      .collection('users')
      .valueChanges()
      .pipe(
        switchMap((users: any[]) => {
          return forkJoin(
            users.map((user: any) => {
              if (!user.email) {
                return this.deleteUser(user);
              }
              return of(null);
            })
          );
        })
      );
  }

  async getUser(credential?: UserCredential | null): Promise<any> {
    const userID: string = credential?.user?.uid
      ? credential.user.uid
      : this.store.get('userID');

    if (userID) {
      const user: any = await this.afs
        .collection('users')
        .doc(userID)
        ?.ref?.get()
        .then((doc) => {
          if (doc) {
            const userExists: boolean = doc?.exists;
            if (userExists) {
              const user: any = doc?.data();
              return user;
            } else {
              return null;
            }
          }
        })
        .catch(() => null);
      return user;
    } else {
      console.error('No user ID found while getting user.');
      return null;
    }
  }

  async initializeUser(credential: UserCredential): Promise<void> {
    const user: User = credential?.user;
    const userID: string = user?.uid;
    const jwt: string = await user?.getIdToken();
    const firestoreUserExists: boolean = await this.exists(credential);

    if (!firestoreUserExists && userID) {
      await this.createUser(credential);
    }

    this.store.set('jwt', jwt);
    this.store.set('email', user?.email);
    this.store.set('user', user);
    this.store.set('userID', userID);

    const firestoreUser: IUser = await this.getUser(credential);
    this.setFirestoreUser(jwt, userID, firestoreUser);
  }

  async setFirestoreUser(
    jwt: string,
    userID: string,
    user: IUser
  ): Promise<void> {
    if (userID) {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${userID}`
      );

      const notifications: NotificationItem[] = await userRef.ref
        .get()
        .then(
          (doc: firebase.firestore.DocumentSnapshot<any>) =>
            doc?.data()?.notifications || this.store.get('notifications')
        );

      const userData: IUser = {
        jwt,
        email: user?.email,
        name: user?.displayName ? user.displayName : user?.name,
        notifications: notifications ? notifications : [],
        plans: user?.plans ? user.plans : [],
        tour: user?.tour ? user.tour : true,
        created: user?.created
          ? user.created
          : new Date().toLocaleString('en-US'),
        updated: new Date().toLocaleString('en-US'),
      };

      await userRef.set(userData, { merge: true });
    } else {
      console.error('No user ID found while setting user.');
    }
  }

  async resetPassword(email: string): Promise<void> {
    await this.afAuth.sendPasswordResetEmail(email);
  }

  async updateUser(user: any, key: string, value: any): Promise<void> {
    const userID: string = user.uid;
    if (userID) {
      let userData: any = {
        updated: new Date().toLocaleString('en-US'),
      };
      userData[key] = value;
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(
        `users/${userID}`
      );
      await userRef.set(userData, { merge: true });
    } else {
      console.error('No user ID found while updating user.');
    }
  }

  async createName(name: string): Promise<void> {
    const user = this.store.get('user');
    await this.updateUser(user, 'name', name);
  }

  async getName(): Promise<string | null> {
    const user: any = this.store.get('user');

    if (user) {
      if (user.displayName) return user.displayName;
      if (user.name) return user.name;

      const firebaseUser = await this.afs
        .collection('users')
        .doc(user.uid)
        .ref.get();
      if (firebaseUser.exists) {
        const userData: any = firebaseUser.data();
        return userData?.name || userData?.displayName || null;
      }
    }

    return null;
  }

  getPlansUserDoesNotHave(allPlans: any[], email: string): Observable<any[]> {
    return this.getUserByEmail(email).pipe(
      switchMap((user: any) => {
        const plansUserHas = user.plans || [];
        const plansUserDoesNotHave = allPlans
          .filter((plan: any) => !plansUserHas.includes(plan.id))
          .filter((plan: any) => !plan.isMasterPlan);
        return of(plansUserDoesNotHave);
      })
    );
  }

  async getPlansByUserID(userID?: string): Promise<any[]> {
    const id: string = userID ? userID : this.store.get('userID');
    if (id) {
      const plans: string[] = await this.afs
        .collection('users')
        .doc(id)
        ?.ref?.get()
        .then(async (doc) => {
          if (doc) {
            const userExists: boolean = doc?.exists;
            if (userExists) {
              const user: any = doc?.data();
              return user?.plans;
            } else {
              return [];
            }
          }
        });
      return await this.getPlansFromUserData(plans)
        .then((data: any[]) => {
          this.store.set('plans', data);
          return data;
        })
        .catch(() => {
          this.store.set('plans', []);
          return [];
        });
    }
  }

  async getUserData(field: string, userID?: string): Promise<any> {
    const id: string = userID || this.store.get('userID');
    return await this.firestore.queryFirestore('users', id, field);
  }

  private async getPlansFromUserData(plans: string[]): Promise<any[]> {
    let plansArray: any[] = [];
    for (let plan of plans) {
      const planInfo: any = await this.firestore.queryFirestore('plans', plan);
      if (planInfo) plansArray.push({ id: plan, data: planInfo });
    }
    return plansArray;
  }

  getEmail(): string {
    const user: any = this.store.get('user');
    return user?.email;
  }

  getUserID(): string {
    const user: any = this.store.get('user');
    return user?.uid;
  }

  getUserIDByEmail(email: string): Observable<string> {
    return this.afs
      .collection('users')
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({
            id: c.payload.doc.id,
            ...(c.payload.doc.data() as any),
          }))
        ),
        map((users) => users.find((user) => user.email === email)?.id || null),
        map((userID) => userID),
        catchError((err) => {
          console.error('Error fetching user by email:', err);
          return of(null);
        })
      );
  }

  async getFirestoreUserNameById(userID: string): Promise<string> {
    const id: string = userID || this.store.get('userID');
    if (id) {
      return await this.afs
        .collection('users')
        .doc(id)
        ?.ref?.get()
        .then(async (doc) => {
          if (doc) {
            const userExists: boolean = doc?.exists;
            if (userExists) {
              const user: any = doc?.data();
              return user?.name;
            } else {
              return null;
            }
          }
        });
    }
    return null;
  }

  async getTourComplete(userID?: string): Promise<boolean> {
    const id: string = userID ? userID : this.store.get('userID');
    if (id) {
      return await this.afs
        .collection('users')
        .doc(id)
        ?.ref?.get()
        .then(async (doc) => {
          if (doc) {
            const user: any = doc?.data();
            const tourComplete = !user?.tour;
            console.log(
              tourComplete
                ? `Client has completed the tour, skipping...`
                : `Client needs to complete the tour.`
            );
            return tourComplete;
          }
        });
    }
  }

  async setTourComplete(): Promise<void> {
    await this.updateUser(this.store.get('user'), 'tour', false);
  }

  getAllUsers(): Observable<any[]> {
    return this.afs
      .collection('users')
      .valueChanges()
      .pipe(
        map((users: any) => users.filter((user: any) => !!user.email)),
        tap((users: any) => this.store.set('users', users)),
        catchError((err) => {
          console.error('Error fetching all users:', err);
          return of([]);
        })
      );
  }

  getUserByEmail(email: string): Observable<any> {
    return this.afs
      .collection('users', (ref) => ref.where('email', '==', email))
      .valueChanges()
      .pipe(map((users) => users[0]));
  }

  getUserRoleByID(uid: string): Observable<string> {
    return this.afs
      .doc<any>(`users/${uid}`)
      .valueChanges()
      .pipe(map((user) => user.role));
  }

  getAllUsersWithRole(): Observable<any> {
    return this.getAllUsers().pipe(
      switchMap((users: any[]) => {
        return forkJoin(
          users.map((user: any) => {
            return this.getUserRoleByID(user.uid).pipe(
              map((role: string) => {
                return { ...user, role };
              })
            );
          })
        );
      })
    );
  }

  deleteUser(user: any): Observable<any> {
    if (!user) {
      console.error('No user provided for deletion.');
      return of(null);
    }

    if (!user.uid) {
      if (!user.email) {
        console.error('No UID or email found for deleting user.');
        return of(null);
      }

      return this.afs
        .collection('users', (ref) => ref.where('email', '==', user.email))
        .snapshotChanges()
        .pipe(
          take(1),
          switchMap((actions) => {
            if (actions.length === 0) {
              console.error('No user found with the given email for deletion.');
              return of(null);
            }
            const uid = actions[0].payload.doc.id;
            return from(this.afs.doc(`users/${uid}`).delete());
          })
        );
    }

    return from(this.afs.doc(`users/${user.uid}`).delete());
  }

  resetUser(): void {
    this.resetUserStore();
    this.resetLocalUser();
  }

  resetUserStore(): void {
    this.store.reset();
    this.store.set('isAdmin', false);
    this.store.set('isAuthenticated', false);
  }

  resetLocalUser(): void {
    this.storage.resetLocalStorage();
  }

  set(key: string, value: any): void {
    this.store.set(key, value);
  }

  switchPlan(): void {
    this.store.set('plans', []);
  }
}
