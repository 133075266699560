import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { BrowserModule, Title } from '@angular/platform-browser';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { JoyrideModule } from 'ngx-joyride';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { LayoutModule } from './layout/layout.module';
import { FirebaseModule } from './pages/admin/dashboards/firebase/firebase.module';
import { ReloadInterceptor } from './core/helpers/reload.interceptor';
import { getApps } from 'firebase/app';
@NgModule({
  declarations: [AppComponent],
  imports: [
    // AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthModule,
    BrowserModule,
    HttpClientModule,
    JoyrideModule.forRoot(),
    SweetAlert2Module.forRoot(),
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    FirebaseModule
  ],
  providers: [
    Title,
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: ReloadInterceptor },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor() {
    // Guard against multiple initializations of Firebase
    const existingApps = getApps();
    if (existingApps.length === 0) {
      AngularFireModule.initializeApp(environment.firebase);
    }
  }

}
