import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { FormsModule } from '@angular/forms';
// import { NgApexchartsModule } from 'ng-apexcharts';
import { RouterModule } from '@angular/router';

import { StatisticsWidgetComponent } from './statistics-widget/statistics-widget.component';
import { ChartCardComponent } from './chart-card/chart-card.component';
import { RoundedProgressChartComponent } from './rounded-progress-chart/rounded-progress-chart.component';
import { ChatGroupComponent } from './chat-group/chat-group.component';
import { UserCardComponent } from './user-card/user-card.component';
import { TodoComponent } from './todo/todo.component';
import { InboxComponent } from './inbox/inbox.component';
import { ChartStatisticsComponent } from './chart-statistics/chart-statistics.component';
import { CountUpModule } from 'ngx-countup';
import { ChatComponent } from './chat/chat.component';
import { PricingCardsComponent } from './pricing-cards/pricing-cards.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { FaqComponent } from './faq/faq.component';

@NgModule({
  declarations: [
    ChatGroupComponent,
    UserCardComponent,
    ChatComponent,
    TodoComponent,
    InboxComponent,
    ChartStatisticsComponent,
    PricingCardsComponent,
    PreloaderComponent,
    FaqComponent,
    StatisticsWidgetComponent,
    ChartCardComponent,
    RoundedProgressChartComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SimplebarAngularModule,
    // NgApexchartsModule,
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    CountUpModule,
  ],
  exports: [
    ChatGroupComponent,
    UserCardComponent,
    ChatComponent,
    TodoComponent,
    InboxComponent,
    ChartStatisticsComponent,
    PricingCardsComponent,
    PreloaderComponent,
    FaqComponent,
    StatisticsWidgetComponent,
    ChartCardComponent,
    RoundedProgressChartComponent,
  ],
})
export class WidgetModule {}
