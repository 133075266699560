<div class="modal-wrapper">
    <div class="modal-head">
        <h2>Start a collection</h2>
        <div class="col-12 col-md-8">
            <div class="d-flex align-items-center justify-content-between mt-2">
                <p class="m-0 d-flex align-items-center"><span>1</span> Give the collection an ID</p>
                <p class="{{ !secondStep ? 'light-text' : '' }}  m-0 d-flex align-items-center"><span>2</span> Add its first document</p>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-6 my-4">
            <div *ngIf="!secondStep" class="form-group">
                <label for="colId" class="mb-2">Collection ID ?</label>
                <input type="text" class="form-control" id="colId" [(ngModel)]="collectionId">
            </div>
            <div *ngIf="secondStep" class="form-group position-relative">
                <label for="docId" class="mb-2">Document ID ?</label>
                <input type="text" class="form-control" id="docId" [(ngModel)]="documentId">
                <button class="btn auto-fill-btn" (click)="genAutoString()">Auto-ID</button>
            </div>
        </div>
    </div>
    <div class="modals-footer">
        <div class="d-flex align-items-center justify-content-end p-4">
            <button class="btn btn-cancel" (click)="close()">Cancel</button>
            <button *ngIf="!secondStep" class="btn btn-next" [disabled]="collectionId.length == 0" (click)="nextStep()">Next</button>
            <button *ngIf="secondStep" class="btn btn-next" [disabled]="collectionId.length == 0 || documentId.length == 0 || btnDisabled" (click)="createNewCollection()">
                <div *ngIf="btnLoader" class="spinner-border spinner-border-sm me-1" role="status">
                    <span class="sr-only">Loading...</span>
                </div>Save</button>
        </div>
    </div>
</div>