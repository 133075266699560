import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { findAllParent, findMenuItem } from '../../shared/helper/utils';
import { MenuItem } from '../../shared/models/menu.model';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { UserService } from '../../../core/services/user/user.service';
import { UserStore } from '../../../core/services/user/user.store';

@Component({
  selector: 'app-horizontal-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
})
export class TopnavComponent implements OnInit {
  @Input() showMobileMenu: boolean = true;

  isAdmin$: Observable<boolean> = this.user.admin$;

  menuItems: MenuItem[] = [];
  activeMenuItems: string[] = [];
  chunkSize: number = 7;

  constructor(
    router: Router,
    private nav: NavigationService,
    private user: UserService,
    private store: UserStore
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  ngOnInit(): void {
    this.isAdmin$.subscribe((isAdmin: boolean) => {
      this.initMenu(isAdmin);
    });
  }

  /**
   * On view init - activating menuitems
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.activateMenu();
    });
  }

  /**
   * Initializing menuitems and controlling how many menu items can be displayed in it
   */
  initMenu(isAdmin: boolean = false): void {
    this.nav.createNavigationRoutes(isAdmin, this.store.get('email'));
    this.menuItems = this.nav.routes;
  }

  splitArray(array: any[], chunkSize: number): any[] {
    const splittedArray: any = Array(Math.ceil(array.length / chunkSize))
      .fill(1)
      .map((_, index) => index * chunkSize)
      .map((begin) => array.slice(begin, begin + chunkSize));

    return splittedArray;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasSubmenu(menu: MenuItem): boolean {
    return menu.children ? true : false;
  }

  /**
   * checks if menuitem has grand children and controls rendering mega menu
   * @param menuItem menu item
   */
  hasGrandChildren(menuItem: MenuItem): boolean {
    let hasGrandChild: MenuItem[] =
      menuItem.children &&
      menuItem.children.filter(
        (child: MenuItem) => child.children?.length && child.children
      );
    return hasGrandChild.length > 0;
  }

  /**
   *  Toggle the dropdown menu
   */
  toggleMenuItem(menuItem: MenuItem): void {
    menuItem.collapsed = !menuItem.collapsed;
    let openMenuItems: string[];
    if (!menuItem.collapsed) {
      openMenuItems = [
        menuItem['key'],
        ...findAllParent(this.menuItems, menuItem),
      ];
      // close other open menu
      this.menuItems.forEach((menu: MenuItem) => {
        if (!openMenuItems.includes(menu.key!)) {
          menu.collapsed = true;
        }
      });
    }
  }

  /**
   * activate the menuitems
   */
  activateMenu(): void {
    const div = document.getElementById('topnav-menu-content');
    let matchingMenuItem = null;
    if (div) {
      let items: any = div.getElementsByClassName('nav-link-ref');
      for (let i = 0; i < items.length; ++i) {
        if (window.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        const mid = matchingMenuItem.getAttribute('data-menu-key');
        const activeMt = findMenuItem(this.menuItems, mid);
        if (activeMt) {
          this.activeMenuItems = [
            activeMt['key'],
            ...findAllParent(this.menuItems, activeMt),
          ];
        }
      }
    }

    // close all menu
    this.menuItems.forEach((menu: MenuItem) => {
      menu.collapsed = true;
    });
  }
}
