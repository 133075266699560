import { Injectable } from '@angular/core';
import decode from 'jwt-decode';

import { UserStore } from '../user/user.store';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private store: UserStore) {}

  isAccessTokenValid(jwt: string): boolean {
    if (!jwt) {
      return false;
    }
    const decodedToken: any = decode(jwt);
    return decodedToken?.exp ? decodedToken.exp * 1000 > Date.now() : false;
  }

  getAccessToken(): string | null {
    const token = this.isAccessTokenValid(this.store.get('jwt'))
      ? this.store.get('jwt')
      : null;
    return token;
  }
}
