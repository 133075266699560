import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import * as feather from 'feather-icons';

import { EventService } from '../../../core/services/event/event.service';
import { MenuItem } from '../models/menu.model';
import { findAllParent, findMenuItem } from '../helper/utils';
import { NavigationService } from '../../../core/services/navigation/navigation.service';
import { UserService } from '../../../core/services/user/user.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit, AfterViewInit {
  @Input() navClasses: string | undefined;
  @Input() includeUserProfile: boolean = false;

  hasTwoToneIcons: boolean = false;
  isInitialized: boolean = false;
  leftSidebarClass = 'sidebar-enable';
  activeMenuItems: string[] = [];
  loggedInUser: any = {};
  menuItems: MenuItem[] = [];

  constructor(
    router: Router,
    private user: UserService,
    private eventService: EventService,
    private nav: NavigationService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
        this.hideMenu();
      }
    });
  }

  ngOnInit(): void {
    this.initMenu();
    this.loggedInUser = this.user.getUser();

    this.eventService.subscribe('toggleTwoToneIcons', (enable) => {
      this.hasTwoToneIcons = enable;
      if (this.hasTwoToneIcons) {
        document.body.setAttribute('data-sidebar-icon', 'twotones');
      } else {
        document.body.removeAttribute('data-sidebar-icon');
      }
    });
  }

  ngOnChanges(): void {
    if (this.includeUserProfile) {
      document.body.setAttribute('data-sidebar-user', 'true');
    } else {
      document.body.setAttribute('data-sidebar-user', 'false');
    }
  }

  /**
   * On view init - activating menuitems
   */
  ngAfterViewInit() {
    setTimeout(() => {
      this.activateMenu();
    });
    feather.replace();
  }

  /**
   * initialize menuitems
   */
  initMenu(): void {
    this.menuItems = this.nav.routes;
  }

  /**
   * activates menu
   */
  activateMenu(): void {
    const div = document.getElementById('side-menu');
    let matchingMenuItem = null;

    if (div) {
      let items: any = div.getElementsByClassName('side-nav-link-ref');
      for (let i = 0; i < items.length; ++i) {
        if (window.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }

      if (matchingMenuItem) {
        const mid = matchingMenuItem.getAttribute('data-menu-key');
        const activeMt = findMenuItem(this.menuItems, mid);
        if (activeMt) {
          const matchingObjs = [
            activeMt['key'],
            ...findAllParent(this.menuItems, activeMt),
          ];

          this.activeMenuItems = matchingObjs;

          this.menuItems.forEach((menu: MenuItem) => {
            menu.collapsed = !matchingObjs.includes(menu.key!);
          });
        }
      }
    }
  }

  /**
   * toggles open menu
   * @param menuItem clicked menuitem
   * @param collapse collpase instance
   */
  toggleMenuItem(menuItem: MenuItem, collapse: NgbCollapse): void {
    collapse.toggle();
    let openMenuItems: string[];
    if (!menuItem.collapsed) {
      openMenuItems = [
        menuItem['key'],
        ...findAllParent(this.menuItems, menuItem),
      ];
      this.menuItems.forEach((menu: MenuItem) => {
        if (!openMenuItems.includes(menu.key!)) {
          menu.collapsed = true;
        }
      });
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasSubmenu(menu: MenuItem): boolean {
    return menu.children ? true : false;
  }

  /**
   * Hides the menubar
   */
  hideMenu() {
    document.body.classList.remove('sidebar-enable');
  }
}
