import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from '../../../services/firebase.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification/notification.service';

@Component({
  selector: 'app-f-create-auth-user',
  templateUrl: './f-create-auth-user.component.html',
  styleUrls: ['./f-create-auth-user.component.scss']
})
export class FCreateAuthUserComponent implements OnInit {
  myForm: FormGroup;
  btnLoader:boolean=false;
  btnDisabled:boolean=false;

  constructor(public dialogRef: MatDialogRef<FCreateAuthUserComponent>,
    private dialog: MatDialog,
    private fbService: FirebaseService,
    private fb: FormBuilder,
    private notificationService:NotificationService
  ) { }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  close() {
    this.dialog.closeAll();
  }

  onSubmit() {
    if(this.myForm.valid){
      this.btnLoader = true;
      this.btnDisabled = true;
        this.fbService.registerUser(this.myForm.value.email, this.myForm.value.password)
          .then((userCredential) => {
            this.btnLoader = false;
            this.btnDisabled = false;
            this.notificationService.showError(
              'User registered:'
              );
              this.dialogRef.close({status:true});
          })
          .catch((error) => {
            this.btnLoader = false;
            this.btnDisabled = false;
            this.notificationService.showError(
              'Error registering user:'
            );
          });
    }
  }
}
