import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, distinctUntilChanged, filter, map } from 'rxjs';

import { MenuItem } from '../../../layout/shared/models/menu.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router, private location: Location) {}

  currentRoute$: Observable<any> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => event as NavigationEnd),
    map((event) => event.urlAfterRedirects),
    distinctUntilChanged()
  );

  navigation$: Observable<boolean>;

  adminDashboard: any = {
    key: 'ds-dashboard-1',
    label: 'Admin Dashboard',
    link: '/admin/dashboard',
    parentKey: 'dashboards',
  };

  billingDashboard: any = {
    key: 'ds-dashboard-2',
    label: 'Billing Dashboard',
    link: '/admin/dashboard/billing',
    parentKey: 'dashboards',
  };

  employeeDashboard: any = {
    key: 'ds-dashboard-3',
    label: 'Employer Dashboard',
    link: '/auth/dashboard',
    parentKey: 'dashboards',
  };

  employeeNotifications: any = {
    key: 'ds-dashboard-4',
    label: 'Employer Notifications',
    link: '/auth/dashboard/notifications',
    parentKey: 'dashboards',
  };

  fileTransferDashboard: any = {
    key: 'ds-dashboard-5',
    label: 'File Transfer Dashboard',
    link: '/admin/dashboard/file-transfers',
    parentKey: 'dashboards',
  };

  githubDashboard: any = {
    key: 'ds-dashboard-6',
    label: 'Updates Dashboard',
    link: '/admin/dashboard/github',
    parentKey: 'dashboards',
  };

  firebasebDashboard: any = {
    key: 'ds-dashboard-7',
    label: 'Firebase Dashboard',
    link: '/admin/dashboard/firebase',
    parentKey: 'dashboards',
  };

  headerDashboard: any = {
    key: 'ds-dashboard-8',
    label: 'Global Parser Dashboard',
    link: '/admin/dashboard/headers',
    parentKey: 'dashboards',
  };

  plansDashboard: any = {
    key: 'ds-dashboard-9',
    label: 'Plans & Users Dashboard',
    link: '/admin/dashboard/plans',
    parentKey: 'dashboards',
  };

  routes: MenuItem[] = [];

  initializeRoutes(): void {
    this.routes = [
      { key: 'navigation', label: 'Navigation Menu', isTitle: true },
      {
        key: 'dashboards',
        label: 'Dashboards',
        isTitle: false,
        icon: 'arrow-right',
        collapsed: true,
        children: [this.employeeDashboard],
      },
    ];
  }

  deleteDashboardRoutes(): void {
    this.routes.find(
      (route: any) => route.key.toLowerCase() === 'dashboards'
    ).children = [];
  }

  createAdminRoutes(isAdmin: boolean = false, email: string): void {
    if (isAdmin) {
      this.routes
        .find((route: any) => route.key.toLowerCase() === 'dashboards')
        .children.push(this.adminDashboard);

      if (email?.includes('ritter') || email?.includes('lindsey')) {
        this.routes
          .find((route: any) => route.key.toLowerCase() === 'dashboards')
          .children.push(this.billingDashboard);
      }

      this.routes
        .find((route: any) => route.key.toLowerCase() === 'dashboards')
        .children.push(this.fileTransferDashboard);

      this.routes
        .find((route: any) => route.key.toLowerCase() === 'dashboards')
        .children.push(this.firebasebDashboard);

      this.routes
        .find((route: any) => route.key.toLowerCase() === 'dashboards')
        .children.push(this.headerDashboard);

      this.routes
        .find((route: any) => route.key.toLowerCase() === 'dashboards')
        .children.push(this.plansDashboard);

      // commenting out for now
      // if (email?.includes('ritter') || email?.includes('lindsey')) {
      //   this.routes
      //     .find((route: any) => route.key.toLowerCase() === 'dashboards')
      //     .children.push(this.githubDashboard);
      // }
    }
  }

  createNavigationRoutes(isAdmin: boolean = false, email: string): void {
    this.initializeRoutes();
    if (isAdmin) {
      this.createAdminRoutes(isAdmin, email);
    }
    this.createDisclaimerRoutes();
    this.createPageRoutes();
    // this.sortRoutes();
  }

  createPageRoutes(): void {
    this.routes.push({
      key: 'pages',
      label: 'Pages',
      isTitle: false,
      icon: 'arrow-right',
      collapsed: true,
      children: [this.employeeNotifications],
    });
  }

  createDisclaimerRoutes(): void {
    this.routes.push({
      key: 'disclaimers',
      label: 'Disclaimers',
      isTitle: false,
      icon: 'arrow-right',
      collapsed: true,
      children: [
        {
          key: 'cookie-policy',
          label: 'Cookie Policy',
          link: '/auth/cookie-policy',
          parentKey: 'disclaimers',
        },
        {
          key: 'privacy-policy',
          label: 'Privacy Policy',
          link: '/auth/privacy-policy',
          parentKey: 'disclaimers',
        },
        {
          key: 'terms-of-service',
          label: 'Terms Of Service',
          link: '/auth/terms-of-service',
          parentKey: 'disclaimers',
        },
      ],
    });
  }

  async navigateToDashboard(): Promise<void> {
    await this.router.navigateByUrl('/auth/dashboard');
  }

  async navigateToNotifications(): Promise<void> {
    await this.router.navigateByUrl('/auth/dashboard/notifications');
  }

  async navigateToAdminDashboard(): Promise<void> {
    await this.router.navigateByUrl('/admin/dashboard');
  }

  async navigateToBillingDashboard(): Promise<void> {
    await this.router.navigateByUrl('/admin/dashboard/billing');
  }

  async navigateToLogin(): Promise<void> {
    await this.router.navigateByUrl('/auth/login', { replaceUrl: true });
  }

  async navigateToRegisterSuccess(email: string): Promise<void> {
    await this.router.navigate(['/auth/confirm', { email: email }]);
    // this.router.navigateByUrl('/auth/register-success');
  }

  async navigateToErrorRegistering(): Promise<void> {
    await this.router.navigateByUrl('/auth/error-registering');
  }

  async navigateToHome(): Promise<void> {
    await this.router.navigateByUrl('/home');
  }

  async navigateToProfile(): Promise<void> {
    await this.router.navigateByUrl('/auth/profile');
  }

  getCurrentUrl(): string {
    return this.router.url;
  }

  isDashboardActive(): boolean {
    return this.router.url.includes('dashboard');
  }

  sortRoutes(): void {
    this.routes.forEach((route) => {
      route.children.sort((a, b) => {
        if (a.key < b.key) {
          return -1;
        }
        if (a.key > b.key) {
          return 1;
        }
        return 0;
      });
    });
  }
}
