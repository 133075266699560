import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { Observable, from, of } from 'rxjs';
import { tap, catchError, filter, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { UserStore } from '../user/user.store';
import { ApiConfig } from '../../interfaces/api-config.model';
import { TableHeadersService } from '../../../core/services/table-headers/table-headers.service';
@Injectable({
  providedIn: 'root',
})
export class AdminService {
  isAdmin$: Observable<boolean> = this.store.select('isAdmin');
  fileFeedbacks$: Observable<any> = this.store.select('fileFeedbacks');
  fileFeedbackById$: Observable<any> = this.store.select('fileFeedbackById');
  inboundFiles$: Observable<any> = this.store.select('inboundFiles');
  outboundFiles$: Observable<any> = this.store.select('outboundFiles');
  payrollTotals$: Observable<any> = this.store.select('payrollTotals');
  payrollFileUploads$: Observable<any[]> =
    this.store.select('payrollFileUploads');
  payrollFileUploadDates$: Observable<string[]> = this.store.select(
    'payrollFileUploadDates'
  );
  webToggles$: Observable<any> = this.store.select('webToggles');

  private AUTH_TOKEN: string = this.store.get('jwt');
  private BASE_URL: string = environment.apiUrl;
  private HTTP_OPTIONS = {
    headers: new HttpHeaders({
      Authorization: `Bearer ${this.AUTH_TOKEN}`,
      UserID: this.store.get('uid')
        ? this.store.get('uid')
        : this.store.get('user')?.uid,
    }),
  };
  private HTTP_HEADERS = this.HTTP_OPTIONS.headers;
  private isAdminStorageKey = 'isAdmin';
  private tableHeadersService: TableHeadersService;

  constructor(private http: HttpClient, private store: UserStore, private injector: Injector) {
    const isAdminFromStorage = localStorage.getItem(this.isAdminStorageKey);
    if (isAdminFromStorage) {
      this.store.set('isAdmin', isAdminFromStorage === 'true');
    }
      }

  rowsToDownload: any[] = [];

  isAdmin(): boolean {
    return this.store.get('isAdmin');
  }

  set(key: string, value: any): void {
    try {
      this.store.set(key, value);
      localStorage.setItem(this.isAdminStorageKey, value.toString());
    } catch (err) {
      console.error(err);
    }

  }

  private request(config: ApiConfig): Observable<any> {
    const {
      endpoint,
      httpMethod = 'GET',
      body,
      responseType,
      queryParams,
    } = config;

    let params = new HttpParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key)) {
        params = params.set(key, queryParams[key]);
      }
    }

    if (httpMethod === 'POST') {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.store.get('jwt')}`,
        UserID: this.store.get('uid')
          ? this.store.get('uid')
          : this.store.get('user')?.uid,
      }),
    };
      return from(
        this.http.post(`${this.BASE_URL}${endpoint}`, {
          body,
          responseType,
          headers: this.HTTP_HEADERS,
          params, 
          },{
            headers: HTTP_OPTIONS.headers})
      ).pipe(catchError((error) => of({ error })));
    } else if (httpMethod === 'PUT') {
      return from(
        this.http.put(`${this.BASE_URL}${endpoint}`, {
          body,
          responseType,
          headers: this.HTTP_HEADERS,
          params,
        })
      ).pipe(catchError((error) => of({ error })));
    } else {
      return from(
        this.http.get(`${this.BASE_URL}${endpoint}`, { params, headers: this.HTTP_HEADERS })
      ).pipe(catchError((error) => of({ error })));
    }
  }
 
  eraseAllPayrollData(): Observable<any> {
    return this.request({
      endpoint: '/admin/delete-file-contents',
      httpMethod: 'POST',
    });
  }

  eraseDataByFileID(fileId: string): Observable<any> {
    return this.request({
      endpoint: '/admin/delete-file-contents',
      httpMethod: 'POST',
      body: { fileId },
    });
  }

  deleteFileUploads(fileIds: string[]): Observable<any> {
    return this.request({
      endpoint: '/payrolls/delete',
      httpMethod: 'POST',
      body: { fileIds },
    });
  }

  receiveSelectedRows(selectedRows: any[]): void {
    this.rowsToDownload=selectedRows
  }


  downloadData(api: string, filename: string, isEditable: boolean, downloadVia): Observable<any> {
    const date = new Date();
    const dateHeader = `Date: ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}\n`;

    let csvData: any[];
    let header: string;
    let headerRow: string;
    let fileContent: any[] = [];
    let tableHeaders: any[];

    this.tableHeadersService = this.injector.get(TableHeadersService);

    if (api === 'payrolls') {
      header = 'Payroll Data\n\n';
      tableHeaders = this.tableHeadersService.PAYROLL_TABLE_HEADERS;
    } else if (api === 'census') {
      header = 'Retirement Plans, Inc - Census Data\n\n';
      tableHeaders = this.tableHeadersService.CENSUS_TABLE_HEADERS;
    }

    headerRow = tableHeaders.map(header => header.label).join(',');
    csvData = [header, dateHeader, headerRow];

    try {
      if (isEditable && this.rowsToDownload.length > 0) {
        fileContent = this.rowsToDownload;
        this.exportToCSV(csvData, fileContent, tableHeaders, filename, downloadVia);
      } else {
        const headers = this.HTTP_HEADERS
        headers.append('Accept', 'text/csv');
        return this.request({
          endpoint: `/${api}/download`,
          headers,
        }).pipe(
          map((data: any) => {
            if (data && !data?.['error']) {
              fileContent = data['body'];
              this.exportToCSV(csvData, fileContent, tableHeaders, filename, downloadVia);
            }
          }),
          catchError(error => {
            console.error('Error occurred while downloading data:', error);
            return of(null);
          })
        );
      }
    } catch (error) {
      console.error('Error occurred:', error);
      return of(null);
    }
  }


  exportToCSV(csvData, fileContent, tableHeaders, filename, downloadVia) {
    fileContent.forEach((item) => {
      const rowValues = tableHeaders.map(header => item[header.name]);
      csvData.push(rowValues.join(','));
    });

    if (!csvData || csvData.length === 0) {
      console.error('Empty CSV data');
      return;
    }

    const blob = new Blob([csvData.join('\n')], { type: 'text/csv;charset=utf-8;' });
    if (downloadVia === 'EMAIL') {
      this.sendEmailWithAttachment(csvData, filename).subscribe(response => {
        console.log(response);
      }, error => {
        console.error(error);
      });
    }
    else {
      saveAs(blob, `${filename}.csv`);
    }
  }


  downloadCensusData(isEditable, downloadVia): Observable<any> {
    return this.downloadData('census', 'CensusData', isEditable, downloadVia);
  }

  downloadPayrollData(isEditable, downloadVia): Observable<any> {
    return this.downloadData('payrolls', 'PayrollData', isEditable, downloadVia);
  }

  downloadFileFeedback(filename: string): Observable<any> {
        return this.request({
      endpoint: '/admin/file-uploads/download',
      queryParams: { filename },
      headers: this.HTTP_HEADERS,
          }).pipe(
      filter((data: any) => !!data),
      map((data: any) => {
        const fileType =
          data.filename.split('.').pop() === 'txt'
            ? 'text/plain;charset=utf-8;'
            : 'text/csv;charset=utf-8;';
        const blob = new Blob([data.data], { type: fileType });
        saveAs(blob, `${data.filename}`);
      })
    );
  }

  getBillingData(): Observable<any> {
    return this.request({
      endpoint: '/admin/billing',
      headers: this.HTTP_HEADERS,
    }).pipe();
  }

  getParticipantData(): Observable<any> {
    return this.request({
      endpoint: '/admin/participants',
      headers: this.HTTP_HEADERS,
    }).pipe(
      filter((participants: any[]) => !!participants && !!participants?.length),
      tap((participants: any[]) =>
        this.store.set('participants', participants)
      ),
      map((participants: any[]) => participants)
    );
  }

  getPayrollFileUploads(): Observable<any> {
    const HTTP_OPTIONS = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.store.get('jwt')}`,
        UserID: this.store.get('uid')
          ? this.store.get('uid')
          : this.store.get('user')?.uid,
      }),
    };
    const init = {
      httpMethod: 'POST',
      body: {
        allPlansToggle: true,
        planId: null,
      },
    };
    return from(this.http.post(`${this.BASE_URL}/payrolls/uploads`, init.body , {headers: HTTP_OPTIONS.headers})).pipe(
      catchError(() => of([])),
      tap((files: any[]) => this.store.set('payrollFileUploads', files)),
      tap((files: any[]) => {
        const payrollFileUploadDates: string[] = [
          ...new Set(files.map((file: any) => file?.upload_date)),
        ];
        this.store.set('payrollFileUploadDates', payrollFileUploadDates);
      }),
      map((data: any[]) => data)
    );
  }

  getFileFeedbacks(): Observable<any> {
    return this.request({
      endpoint: '/admin/file-feedbacks',
      headers: this.HTTP_HEADERS,
    }).pipe(
      filter((response: any) => !!response && !!response?.data),
      map((response: any) => response?.data),
      tap((feedbacks: any[]) => this.store.set('fileFeedbacks', feedbacks)),
      map((feedbacks: any[]) => feedbacks)
    );
  }

  getFileFeedbackById(feedbackKey: string): Observable<any> {
    return this.request({
      endpoint: `/admin/file-feedbacks?feedbackKey=${feedbackKey}`,
      headers: this.HTTP_HEADERS,
    }).pipe(
      filter((response: any) => !!response && !!response?.data),
      map((response: any) => response?.data),
      tap((feedback: any) => this.store.set('fileFeedbackById', feedback)),
      map((feedback: any) => feedback)
    );
  }

  getInboundFiles(): Observable<any> {
    return this.request({
      endpoint: '/admin/payrolls/inbound',
      headers: this.HTTP_HEADERS,
    }).pipe(
      filter((response: any) => !!response && !!response?.data),
      map((response: any) => response?.data),
      tap((files: any[]) => this.store.set('inboundFiles', files)),
      map((files: any[]) => files)
    );
  }

  getOutboundFiles(): Observable<any> {
    return this.request({
      endpoint: '/admin/payrolls/outbound',
      headers: this.HTTP_HEADERS,
    }).pipe(
      filter((response: any) => !!response && !!response?.data),
      map((response: any) => response?.data),
      tap((files: any[]) => this.store.set('outboundFiles', files)),
      map((files: any[]) => files)
    );
  }

  generateTrowePriceInboundFile(): Observable<any> {
    return this.request({
      endpoint: '/admin/notification-center/payrolls/troweprice/inbound',
      headers: this.HTTP_HEADERS,
    });
  }

  getAllFirebaseUsers(): Observable<any> {
    return this.request({
      endpoint: '/admin/users',
      headers: this.HTTP_HEADERS,
    });
  }

  getPayrollTotals(): Observable<any> {
    return this.request({
      endpoint: '/admin/payroll-totals',
      headers: this.HTTP_HEADERS,
    }).pipe(
      map((data: any) => data?.data),
      filter((data: any[]) => !!data && !!data[0]),
      tap((data: any[]) => this.store.set('payrollTotals', data))
    );
  }

  getWebToggles(
    adminToggles: boolean = false,
    authToggles: boolean = false,
    planID: string = null
  ): Observable<any> {
    const endpoint = adminToggles
      ? '/admin/web-toggles?filter=admin_toggles'
      : authToggles
      ? '/admin/web-toggles?filter=auth_toggles'
      : `/admin/web-toggles?planID=${planID}`;
    return this.request({ endpoint, headers: this.HTTP_HEADERS }).pipe(
      filter((toggles: any) => !!toggles && !!toggles?.id),
      map((toggles: any) => {
        delete toggles.id;
        return toggles;
      }),
      tap((toggles: any) => this.store.set('webToggles', toggles))
    );
  }

  resubmitFile(file: any): Observable<any> {
    return this.request({
      endpoint: '/payrolls/resubmit',
      httpMethod: 'POST',
      body: { file },
      headers: this.HTTP_HEADERS,
    });
  }

  setWebToggle(toggle: string, value: any): Observable<void> {
    return this.request({
      endpoint: '/admin/web-toggles',
      httpMethod: 'POST',
      body: { toggle, value },
      headers: this.HTTP_HEADERS,
    });
  }

  sendPayrollDataToTransamerica(specificDate?: string): Observable<any> {
    return this.request({
      endpoint: '/admin/notification-center/payrolls/transamerica/send',
      headers: this.HTTP_HEADERS,
      queryParams: specificDate ? { specificDate } : null,
    });
  }

  sendPayrollDataToTrowePrice(specificDate?: string): Observable<any> {
    return this.request({
      endpoint: '/admin/notification-center/payrolls/troweprice/send',
      headers: this.HTTP_HEADERS,
      queryParams: specificDate ? { specificDate } : null,
    });
  }

  sendFileCountEmail(): Observable<any> {
    return this.request({
      endpoint: '/admin/notification-center/daily-counts/send',
      headers: this.HTTP_HEADERS,
    });
  }

  downloadCSV(file: any): Observable<any> {
    return this.request({
      endpoint: '/admin/file-uploads/download',
      httpMethod: 'POST',
      body: { id: file.file_name },
      headers: this.HTTP_HEADERS,
    }).pipe(
      tap((data: ArrayBuffer) => {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, `${file.file_name}`);
      })
    );
  }

  sendConfirmationEmail(email: string, fileId: string): Observable<any> {
    return this.request({
      endpoint: '/admin/file-uploads/confirmation',
      httpMethod: 'POST',
      body: { id: fileId, email },
      headers: this.HTTP_HEADERS,
    });
  }

  sendEmailWithAttachment(csvData, fileName): Observable<any> {
    try {
      const payload = {
        csvData: csvData,
        filename: fileName,
      };
      return this.request({
        endpoint: '/admin/sendAttachment',
        httpMethod: 'POST',
        body: payload,
        headers: this.HTTP_HEADERS,
      });
    } catch (error) {
      console.log(error);
    }
  }

  getCensusParserMappings() {
    return this.request({
      endpoint: '/admin/census-mappings',
      headers: this.HTTP_HEADERS,
    });
  }

  updateCensusParserMappings(mappings: any) {
    return this.request({
      endpoint: '/admin/census-mappings/update',
      httpMethod: 'POST',
      body: { mappings },
      headers: this.HTTP_HEADERS,
    });
  }

  getPayrollParserMappings() {
    return this.request({
      endpoint: '/admin/payroll-mappings',
      headers: this.HTTP_HEADERS,
    });
  }

  updatePayrollParserMappings(mappings: any) {
    return this.request({
      endpoint: '/payroll-mappings/update',
      httpMethod: 'POST',
      body: { mappings },
      headers: this.HTTP_HEADERS,
    });
  }

  getAwsBillingAndCostData(granularity, startingDate, endingDate) {
    const payload = {
      granularity: granularity,
      startingDate: startingDate,
      endingDate: endingDate
    };
    return this.request({
      endpoint: '/admin/awsBillingAndCost',
      headers: this.HTTP_HEADERS,
      queryParams: payload
    });
  }


  getFirebaseBillingAccountInfo(environment) {
    const payload = {
      environment: environment
    };
    return this.request({
      endpoint: '/admin/firebaseBillingInfo',
      headers: this.HTTP_HEADERS,
      queryParams: payload
    });
  }


  downloadOrEmailInvoice(via, selectedInvoice) {
    const payload = {
      via: via,
      selectedInvoice: selectedInvoice
    };
    return this.request({
      endpoint: '/admin/downloadOrEmailAwsInvoice',
      headers: this.HTTP_HEADERS,
      queryParams: payload
    });

  }


  getRpiBillingStatements() {
    return this.request({
      endpoint: '/admin/getRpiBillingStatements',
      headers: this.HTTP_HEADERS,
    });
  }


  
}
